.business-search-filter-wrapper {
	z-index: 11;
	background-color: $white;
	// transform: translateY(0);
	// transition: transform ease .3s;
	// .filter-wrapper.business-search-filter {
	// }

	.filter-header {
		.text {
			margin-bottom: 0;
		}

		> .text {
			padding: 1em;
			// mobile filter header "FILTERS"
			white-space: nowrap;
		}

		.filter-tabs {
			display: flex;
			padding: 1em;
			background-color: $accordion-title-grey;

			.filter-tab {
				display: flex;
				flex: 1;
				// width: calc(100% / 3);
				align-items: center;
				justify-content: center;
				padding: 3px 0;
				border: solid $dinova-green 2px;
				margin: 0 .25em;
				background-color: $white;
				cursor: pointer;
				font-weight: 400;
				text-align: center;

				.text {
					// max-width: 150px;
					color: $dinova-green;
				}

				&.active {
					background-color: $dinova-green;

					.text {
						color: $white;
					}
				}

				&.disabled {
					border-color: $grey-text;

					.text {
						color: $grey-text;
					}
				}
			}
		}
	}

	.filter-tab-panel {
		display: none;
		height: calc(100vh - 318px); // height of footer buttons
		padding: 0 1em;
		overflow-x: hidden;
		overflow-y: scroll;

		&.active {
			display: block;
		}

		.form-check {
			padding: 23px 7px;
			border-bottom: solid $accordion-title-grey 2px;
			margin: 0 !important;

			&:last-child {
				border-bottom: none;
			}

			label {
				padding-left: 37px !important;
			}

			label:after {
				top: 1px !important;
				width: 25px !important;
				height: 25px !important;
			}

			input[type='checkbox']:checked + label:before {
				top: -1px !important;
				left: 2px !important;
				width: 13px !important;
				height: 22px !important;
				border-radius: 2px;
				border-width: 0px 4px 4px 0px !important;
			}
		}
	}

	.business-search-filter-footer {
		padding: 0 1em;

		button {
			&:nth-child(1) {
				margin-top: 0px;
			}
		}
	}
}

// mobile only
@media screen and (max-width: 767px) {
	.business-search-filter-wrapper {
		position: fixed;
		z-index: 11;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		max-height: 100vh;
		transition: top ease .3s;
	}

	.business-search-filter-wrapper.slide-up {
		top: -100vh;
	}
}

@media screen and (min-width: 768px) {
	.search-top-bar .inner-wrapper .bottom-bar {
		padding: 0px !important;
		margin-top: 10px;

		.text.sm.bold.text-none {
			padding: 0px !important;
		}
	}

	.business-search-filter-wrapper {
		z-index: 10;
		background-color: $accordion-title-grey;

		.business-search-filter {
			.filter-header {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				background-color: $accordion-title-grey;

				.text.sm.text-none {
					padding-right: 5px;
					padding-left: 5px;
					margin-right: 3px;

					@include fluid-type-default(12px, 16px);
				}

				.filter-tabs {
					width: unset;
					min-width: unset;
					// max-width: 60vw;
					padding: 0px;
					margin: 0;

					.filter-tab {
						width: unset;
						padding: 10px;
						margin: 0px 3px;

						.text {
							max-width: none;
							font-weight: 600;
							letter-spacing: .04em;
						}

						.floating-filter {
							position: absolute;
							width: auto;
							padding: 0px;
							border: solid 1px $dinova-green;
							margin: 0 auto;
							// -ms-grid-columns: auto auto;
							grid-gap: 0 1em;
							grid-template-columns: auto auto;
							visibility: hidden;

							.form-check {
								&.my-3 {
									margin-top: 0px !important;
									margin-bottom: 0px !important;
								}

								label {
									height: 100%;
									padding-top: 1rem;
									padding-right: 1em;
									padding-bottom: 1rem;
									white-space: nowrap;

									&:before,
									&:after {
										top: 30% !important;
									}
								}
							}
						}

						&.active {
							.floating-filter {
								z-index: 9999;
								display: grid;
								background-color: $white;
								background-color: #fff;
								color: #444;
								visibility: visible;

								.caret {
									position: absolute;
									top: -8px;
									width: 0;
									height: 0;
									// if change border width, must update SearchFilter.setCaretLefts left calculation
									border-right: 8px solid transparent;
									border-bottom: 8px solid $white;
									border-left: 8px solid transparent;
								}

								.form-check {
									text-align: left;
								}
							}

							.floating-filter.amenities {
								&:before {
									left: var(--caret-left-amenities) px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.business-search-filter-wrapper {
		.business-search-filter {
			.filter-header {
				.text.sm.text-none {
					@include fluid-type-default(16px, 18px);

					&.normal {
						font-weight: 400;
					}
				}

				.filter-tabs {
					.filter-tab {
						padding: 12px 15px;
						margin: 0 4px;

						.floating-filter:not(.recommendations) {
							// -ms-grid-columns: auto auto auto;
							grid-template-columns: auto auto auto;
						}
					}
				}
			}
		}
	}
}
