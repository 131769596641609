@import '~bootstrap/scss/mixins';
@import './variables';
@import './mixins/responsive';
@import './common/base.scss'; // @import './normalize/index.scss';
@import './atoms/index.scss';
@import './ecosystems/index.scss';
@import './organisms/index.scss';
@import './molecules/index.scss';


html {
	scroll-behavior: smooth;
}

body {
	box-sizing: border-box;
	margin: 0;
}

.smartbanner-show.smartbanner-margin-top.with-modal {
	margin-top: 0px !important;
}

.orange-text {
	color: $loading-orange !important;
}
