@media screen and (min-width: 1024px) {
	.input-container {
		.input-label {
			@include fluid-type-default(16px, 18px);
		}
	}

	.input-container.password,
	.input-container.text,
	.input-container.email {
		.input-label {
			top: 18px;
			margin-left: 11px;
		}

		&.error {
			.input-label {
				margin-left: 62px;
			}
		}
	}
}
