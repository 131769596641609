.rewards-error-msg {
	max-width: 620px;

	p {
		margin-bottom: 20px;
		color: $black-text;
		// font: 30px/1.2 'Lato', sans-serif;
		text-align: center;

		@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 26px, 30px);

		// for table and up
		@include media-breakpoint-up(md) {
			@include fluid-type(map-get($grid-breakpoints, md), map-get($grid-breakpoints, lg), 24px, 28px);
		}

		// for desktop
		@include media-breakpoint-up(lg) {
			@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 16px, 18px);
		}
	}
}
