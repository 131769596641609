.rewards-list {
	padding: 0 25px;

	> .row {
		margin: 0 -20px;

		.reward-wrapper:not(:last-child) {
			padding: 0 20px;
			margin-bottom: 50px;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		padding-right: 40px;
		padding-left: 40px;

		> .row .reward-wrapper {
			margin-bottom: 40px;
		}
	}
}
