/* stylelint-disable no-descending-specificity */

.search-tab.catering-search-tab {
	.input-group-append {
		margin-left: 0px;
	}

	.location-input-wrapper.error {
		position: relative;

		.search-dropdown {
			.search-input {
				padding-top: -2px;
				padding-bottom: -2px;
				border-color: $danger-red;
				border-width: 3px;
			}
		}

		span.error {
			background-color: $white;
			color: red;

			@media screen and (min-width: 768px) {
				position: absolute;
				z-index: 2;
				top: 100%;
			}
		}
	}

	@media screen and (max-width: 767px) {
		.search-tab-footer {
			img.splash {
				display: none;
			}
		}
	}

	@media screen and (min-width: 768px) {
		&.hasError {
			.search-tab-footer {
				margin-top: 40px;
			}
		}

		.search-tab-footer {
			position: relative;
			// z-index: 2;

			img.splash {
				position: absolute;
				bottom: -1rem;
				left: -13px;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.search-group {
			[class*='input-wrapper'] {
				&:first-child {
					width: auto;
					flex: unset;

					.dropdown {
						height: 100%;
					}
				}

				&:nth-child(2) {
					input {
						border-radius: 0;
						border-right-width: 0;
					}
				}

				&:nth-child(3) {
					width: auto;
					flex: unset;

					.calendar-dropdown {
						height: 100%;
					}

					button {
						border-radius: 0 !important;
					}
				}

				.title {
					line-height: 42px;
				}
			}

			.search-dropdown {
				.form-control {
					line-height: 42px;
				}
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.search-group {
			[class*='input-wrapper']:nth-child(2) input {
				padding: 16px 15px;
			}

			.search-btn-wrapper {
				.search-btn {
					padding: 9px 0 10px;
				}
			}
		}

		.search-tab-footer {
			position: relative;
			// z-index: 2;

			img.splash {
				left: -7px;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.search-dropdown:not(.near-field) {
		.dropdown-list {
			width: calc(100% + 1px);
		}
	}
}

@media screen and (min-width: 1024px) {
	.hasError {
		.search-group {
			[class*='input-wrapper']:nth-child(2) input {
				padding: 14px 15px !important;
			}
		}
	}
}
