.share-box {
	.darker {
		font-size: 2rem;
		font-weight: 400;
	}

	.learn-more {
		position: relative;
		display: block;
		overflow: hidden;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		color: $dinova-green;
		font-weight: 600;
		text-decoration: none;

		&:after,
		&:before {
			position: absolute;
			top: 50%;
			width: 100%;
			border: solid $grey 1px;
			content: '';
		}

		&:after {
			right: calc(50% - (50px) * -1);
		}

		&:before {
			left: calc(50% - (50px) * -1);
		}
	}

	.link {
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
		overflow-wrap: break-word;
		-ms-word-break: break-all;
		word-break: break-all;
		word-break: break-word;
		word-wrap: break-word;
	}

	button {
		font: 20px/1.2 'Lato', sans-serif;
		white-space: nowrap;

		// stylelint-disable-next-line length-zero-no-unit
		@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 14px, 20px);
	}
}
