@import './has_side_icon';
@import './no_side_icon';

// Base Side Icon Wrapper Styles
.Di_HasSideIcon,
.Di_NoSideIcon {
	margin-top: 15px;

	.md-form input {
		box-sizing: border-box;
		padding-right: 10px;
		padding-left: 10px;
		border: 1px solid $input-border-grey !important;
		background-color: transparent;
		border-radius: 5px;
		box-shadow: none;
		transition: all .3s;

		&:focus {
			+ label.active {
				color: $black;
			}
		}
	}

	&.col {
		padding-right: 0;
		padding-left: 0;
	}
}

// Base Side Icon Styles
.Di_SideIcon {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
	height: 60px;
	padding-top: 6px;
	padding-bottom: 8px;
	padding-left: 0;
	color: $black;
	text-align: center;
	transition: all .3s;

	a {
		color: $black;
	}

	.md-form input:focus {
		border: 2px;
		background-color: $white;
	}
}

// Error Has Icon Styles
.Di_HasError .Di_SideIcon {
	border-width: 0;
}
