@media screen and (min-width: 1024px) {
	p.text.input-error-text {
		@include fluid-type-default(14px, 16px);

		padding-left: 21px;
		margin: 10px 0 0;
	}

	.input-container.error {
		svg {
			position: absolute;
			width: 26px;
			height: 26px;
		}
	}

	.input-container.error.text,
	.input-container.error.password,
	.input-container.error.select,
	.input-container.error.email {
		svg {
			top: 14px;
			left: 19px;
		}
	}
}
