.restaurant-description {
	overflow: hidden;

	.LinesEllipsis {
		margin-bottom: 0px;
		line-height: 1.75;
		text-overflow: ellipsis;
		white-space: pre-line;

		@include fluid-type-default(13px, 16px);
	}

	.btn.btn-warning {
		width: 100% !important;

		@include fluid-type-default(13px, 16px);
	}
}

@media screen and (min-width: 768px) {
	.restaurant-description {
		.btn {
			margin-right: 0;
			margin-left: 0;
		}
	}
}

@media screen and (min-width: 1024px) {
	.restaurant-description {
		.btn {
			width: 90%;
			margin-right: auto;
			margin-left: auto;
		}
	}
}
