.dashboard-banner {
	img {
		width: 100%;
		visibility: hidden;
	}

	.banner {
		background-image: url('#{$cdn-basePath}/mydinova/Promotions/refer-sv.png');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.close {
		position: absolute;
		top: 0;
		right: 5%;
		width: 20px;
		height: 20px;
		border: none !important;
		background-color: transparent !important;
		background-image: url('#{$cdn-basePath}/icons/icon-close.svg');
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 1;
	}

	@media (min-width: 1024px) {
		.no-gutters {
			margin: 0;
		}

		.banner {
			background-image: url('#{$cdn-basePath}/mydinova/Promotions/refer-lv.png');
		}
	}

	@media (min-width: 768px) {
		.no-gutters {
			margin: 0;
		}

		.no-gutters > .col,
		.no-gutters > [class*='col-'] {
			padding-right: 0;
			padding-left: 0;
		}

		.close {
			position: absolute;
			right: 10px;
			width: 30px;
			height: 30px;
		}
	}
}
