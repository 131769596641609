.icon-nav-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	color: $black;
	text-decoration: none;

	&:not(:first-child) {
		margin-left: 34px;
	}

	&.active {
		color: $dinova-green;
	}

	img {
		width: 27px;
		height: 27px;
		margin-right: 5px;
		margin-left: 5px;
	}

	span {
		font-size: 12px;
		font-weight: 500;
		line-height: 20px;
	}

	&:hover {
		color: $black;
		text-decoration: none;

		&.active {
			color: $dinova-green;
		}
	}
}

@media screen and (min-width: 768px) {
	.icon-nav-item {
		height: 55px;
	}
}
