.recommendation-item {
	min-height: 80px;
	align-items: center;
	padding: .5em 0;

	&:first-child {
		margin-top: .5em;
	}

	&:last-child {
		margin-bottom: .5em;
	}

	div[class*='col'] {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		font-weight: 400;

		&.details {
			@media screen and (max-width: 768px) {
				flex-flow: column nowrap;

				> * {
					margin-bottom: .5em;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	p.text {
		margin-bottom: 0px;

		&.bold {
			font-weight: 600 !important;
		}
	}

	.link,
	.rec,
	.date,
	.details,
	.edit {
		p.text {
			@include fluid-type-default(14px, 16px);
		}
	}

	.rec {
		p.text {
			// margin: 1em;
			font-weight: 600;
		}

		&.pos {
			p.text {
				padding: .5em;
				border: solid $dinova-green 2px;
				color: $dinova-green;
			}
		}
	}

	.details {
		// sv - address
		.link + p.text {
			@include fluid-type-default(12px, 12px);
		}
	}

	.restaurant {
		p.text:nth-child(2) {
			// mv, lv - address
			@include fluid-type-default(12px, 12px);

			white-space: nowrap;
		}
	}
}
