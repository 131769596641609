/* stylelint-disable no-descending-specificity */
.top-border-wrapper {
	position: absolute;
	z-index: 2;
	top: 0px;
	display: flex;
	width: 100%;
	pointer-events: none;

	.focus-border {
		border-color: $input-border-grey;
		border-radius: 5px 0 0;
		border-style: solid;
		border-width: 1px 0 0;
		transition: border .3s ease;

		&.front {
			width: 11px;
		}

		&.back {
			flex: 1 1 100%;
			border-radius: 0 5px 0 0;
		}
	}

	.transparent {
		// display: inline-flex;
		// flex: auto;
		margin-left: 4px;
		color: transparent;
		white-space: nowrap;

		@include fluid-type-default(12px, 16px);
	}
}

.textarea {
	.top-border-wrapper {
		top: 0px;
	}
}

.password {
	.top-border-wrapper {
		// top: 4px;
	}
}

// Dropdown
.dropdown {
	.top-border-wrapper {
		.focus-border {
			&.front {
				width: 17px;
			}
		}

		.transparent {
			margin-left: 5px;
		}
	}
}

// Required
.required {
	.top-border-wrapper {
		.transparent {
			margin-left: 10px;
		}
	}

	// Required Dropdown
	.dropdown {
		.transparent {
			margin-left: 11px;
		}
	}
}

// Error Dropdown
.error {
	.dropdown {
		.transparent {
			margin-left: 7px;
		}
	}

	.top-border-wrapper {
		// top: 2px;
	}

	&.password {
		.top-border-wrapper {
			top: 3px;
		}
	}
}

// Required Error Dropdown
.required.error {
	.dropdown {
		.transparent {
			margin-left: 12px;
		}
	}
}

// Open, Focused
.open,
.focused {
	.top-border-wrapper {
		.focus-border {
			border-color: $dinova-green;
		}
	}
}

// Open, Focused, Error
.open,
.focused,
.error.input-container {
	.top-border-wrapper {
		.focus-border {
			border-width: 3px 0 0;
		}
	}
}

// Error
.error.input-container {
	.top-border-wrapper {
		.focus-border {
			border-color: $danger-red;
		}
	}
}
