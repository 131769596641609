// Variables Pro
$border-radius-rounded: 10em !default;

// Gradients Premium
$gradients-premium: () !default;
$gradients-premium: map-merge((
  "purple": (
    "start": #ff6ec4,
    "end": #7873f5
  ),
  "peach": (
    "start": #FFD86F,
    "end": #FC6262
  ),
  "aqua": (
    "start": #2096ff,
    "end": #05ffa3
  ),
  "blue": (
    "start": #45cafc,
    "end": #303f9f
  ),
), $gradients-premium);

// Accordion
$accordion-card-header-padding-y:      1rem !default;
$accordion-card-header-padding-x:      1.5rem !default;
$accordion-rotate-icon-transform:      rotate(180deg) !default;
$accordion-link-hover-transition:      ease-in-out .3s !default;
$accordion-card-body-line-height:      1.7 !default;
$accordion-card-body-color:            #626262 !default;
$accordion-card-body-font-size:        .9rem !default;

// Animations
$animations-pro-transform:             $accordion-rotate-icon-transform !default;
$animations-pro-transition:            all 150ms ease-in 0s !default;

// Autocomplete
$autocomplete-margin-bottom:           1px !default;
$autocomplete-clear-top:               .5rem !default;
$autocomplete-clear-color:             #a6a6a6 !default;
$autocomplete-wrap-max-height:         210px !default;
$autocomplete-wrap-li-padding-y:       12px !default;
$autocomplete-wrap-li-padding-x:       15px !default;
$autocomplete-wrap-li-font-size:       .875rem !default;
$autocomplete-wrap-li-hover-bg:        rgb(238, 238, 238) !default;
$form-inline-autocomplete-width:       15rem !default;

// Buttons
$btn-floating-width:                   47px !default;
$btn-floating-height:                  $btn-floating-width !default;
$btn-floating-icon-size:               1.25rem !default;
$btn-floating-line-height:             $btn-floating-width !default;

$btn-floating-width-sm:                $btn-floating-width / 1.3 !default;
$btn-floating-height-sm:               $btn-floating-width-sm !default;
$btn-floating-icon-size-sm:            $btn-floating-icon-size / 1.3 !default;
$btn-floating-line-height-sm:          $btn-floating-width-sm !default;

$btn-floating-width-lg:                $btn-floating-width * 1.3 !default;
$btn-floating-height-lg:               $btn-floating-width-lg !default;
$btn-floating-icon-size-lg:            $btn-floating-icon-size * 1.3 !default;
$btn-floating-line-height-lg:          $btn-floating-width-lg !default;

$btn-floating-transtion:               $transition-basic !default;
$btn-floating-margin:                  10px !default;
$btn-floating-border-radius:           $border-radius-circle !default;

$fixed-action-btn-right:               35px !default;
$fixed-action-btn-bottom:              $fixed-action-btn-right !default;
$fixed-action-btn-padding-top:         15px !default;
$fixed-action-btn-ul-bottom:           64px !default;
$fixed-action-btn-li-margin-bottom:    15px !default;

$btn-rounded-border-radius:            $border-radius-rounded !default;

// Social buttons
$btn-counter-margin-top:               0px !default;
$btn-sm-counter-margin-top:            8px !default;
$btn-md-counter-margin-top:            5px !default;
$btn-lg-counter-margin-top:            -4px !default;
$btn-counter-margin-left:              -23px !default;
$btn-counter-border-radius:            $border-radius-rounded !default;
$btn-counter-padding-y:                1px !default;
$btn-counter-padding-x:                7px !default;
$btn-counter-bgc:                      rgb(254, 18, 18) !default;
$btn-counter-font-size:                11px !default;

$icons-sm-margin:                      .5rem !default;
$icons-sm-padding:                     $icons-sm-margin !default;
$icons-sm-font-size:                   1.2rem !default;

// Blog
$blog-comment-date-text-color:         #666 !default;
$personal-sm-transition-duration:      .2s !default;
$personal-sm-hover-transform:          scale(1.2) !default;

// Cards
$card-up-size:                         120px !default;
$card-up-img-size:                     200px !default;
$card-avatar-margin-top:               -60px !default;
$card-wrapper-min-height:              500px !default;
$card-wrapper-perspective:             800px !default;
$card-rotating-transform-style:        preserve-3d !default;
$card-front-effect-click-transform:    rotateY(180deg) !default;
$card-back-effect-click-transform:     rotateY(0) !default;
$card-front-back-transition:           1s !default;
$card-back-transform:                  rotateY(-180deg) !default;

$card-cascade-wider-card-body-mx: 4% !default;
$card-cascade-wider-card-body-bg: $white !default;
$card-cascade-reverse-mt: -1rem !default;
$card-cascade-narrower-mt: 1.25rem !default;
$card-cascade-narrowe-view-mx: $card-cascade-wider-card-body-mx !default;
$card-cascade-narrower-view-mt: -1.25rem !default;
$card-cascade-header-gradient-py: 1.6rem !default;
$card-cascade-header-gradient-px: 1rem !default;
$card-cascade-header-gradient-btn-floating-bgc: rgba(255, 255, 255, .2) !default;

$card-btn-action-my: -1.44rem !default;
$card-activator-font-size: 1.2rem !default;
$card-reveal-title-i-color: #9e9e9e !default;
$card-reveal-title-i-right: .63rem !default;
$card-share-social-reveal-top: -1.44rem !default;
$card-share-social-reveal-right: 1.88rem !default;
$card-share-social-reveal-transition: transform 0.35s ease !default;

$panel-cascade-view-color: $white !default;
$panel-cascade-list-group-item-border-bottom: #eee !default;
$panel-cascade-list-group-item-color: #495057 !default;
$panel-cascade-list-group-item-bgc: $panel-cascade-list-group-item-border-bottom !default;

// Charts
$min-chart-width:                      110px !default;
$min-chart-height:                     $min-chart-width !default;
$min-chart-margin:                     50px !default;
$min-chart-line-height:                $min-chart-width !default;
$min-chart-percent-margin:             .1em !default;
$min-chart-percent-font-size:          .8rem !default;

// Forms
$form-header-color: $white !default;
$form-header-mt: -3.13rem !default;
$form-header-mb: 3rem !default;
$form-header-padding: 1rem !default;
$form-header-border-radius: $border-radius-base !default;

// File input
$file-path-wrapper-padding-left:       10px !default;
$file-path-wrapper-height:             2.5rem !default;
$file-input-path:                      2.1rem !default;
$file-input-filter:                    alpha(opacity=0) !default;

// Input group
$input-group-text-checkbox-pl:        18px !default;
$input-group-text-checkbox-height:    20px !default;
$input-group-text-radio-pl:           24px !default;
$input-group-text-radio-height:       24px !default;

// Range input
$input-range-my:                      15px !default;
$input-range-mx:                      0px !default;
$input-range-thumb-top:               10px !default;
$input-range-thumb-margin-left:       -6px !default;
$input-range-transform-origin:        50% 50% !default;
$input-range-transform:               rotate(-45deg) !default;
$input-range-thumb-value-width:       30px !default;
$input-range-thumb-value-transform:   rotate(45deg) !default;
$input-range-active-value-ml:         -1px !default;
$input-range-active-value-mt:         8px !default;
$input-range-active-value-font-size:  10px !default;
$slider-runnable-track-height:        3px !default;
$slider-runnable-track-bg:            #c2c0c2 !default;
$slider-thumb-height:                 14px !default;
$slider-thumb-width:                  $slider-thumb-height !default;
$slider-thumb-mt:                     -5px !default;
$slider-thumb-transition:             .3s !default;
$slider-runnable-track-focus-bg:      #ccc !default;
$slider-border-width:                 6px !default;

// Chips
$chip-height:                         32px !default;
$chip-font-size:                      13px !default;
$chip-font-weight:                    500 !default;
$chip-font-color:                     rgba($black-base, .6) !default;
$chip-line-height:                    32px !default;
$chip-padding-right:                  12px !default;
$chip-br:                             16px !default;
$chip-background-color:               $blue-grey-lighten-5 !default;
$chip-margin-bottom:                  1rem !default;
$chip-margin-right:                   1rem !default;
$chip-img-margin-right:               8px !default;
$chip-img-margin-left:                -12px !default;
$chip-img-height:                     32px !default;
$chip-img-width:                      32px !default;
$chip-close-font-size:                16px !default;
$chip-close-line-height:              32px !default;
$chip-close-padding-left:             8px !default;

$chips-margin-bottom:                 30px !default;
$chips-min-height:                    45px !default;
$chips-padding-bottom:                1rem !default;
$chips-input-font-color:              rgba($black-base, .6) !default;
$chips-input-font-size:               13px !default;
$chips-input-font-weight:             500 !default;
$chips-input-height:                  32px !default;
$chips-input-margin-right:            20px !default;
$chips-input-line-height:             32px !default;
$chips-input-width:                   120px !default;

// Tabs
$tabs-padding:                        0.7rem !default;
$tabs-margin-x:                       1rem !default;
$tabs-margin-y:                      -20px !default;
$pills-padding:                       0.6rem !default;
$nav-tabs-pills-bgc: $default-color !default;
$nav-tabs-border-radius:              $border-radius-base !default;
$nav-link-transition: all .4s !default;
$nav-link-active-bgc: rgba(255, 255, 255, 0.2) !default;
$nav-link-active-transition: all 1s !default;
$nav-item-disabled-link-color: #6c757d !default;
$tab-content-padding: 1rem !default;
$tab-content-pt: 2rem !default;
$md-pills-nav-link-border-radius: 2px !default;
$md-pills-nav-link-color: #666 !default;

// Tabs classic
$classic-tabs-padding-y:              20px !default;
$classic-tabs-padding-x:              24px !default;
$classic-tabs-font-size:              13px !default;
$classic-tabs-color:                  rgba($white, .7) !default;
$classic-tabs-margin-left:            56px !default;
$classic-tabs-border-radius: .3rem !default;
$classic-tabs-li-a-active-border-bottom: 3px solid !default;

// Skins
$skins: () !default;
$skins: map-merge((
  "white": (
    "skin-primary-color":      #4285F4,
    "skin-navbar":             $white,
    "skin-footer-color":       #6f7178,
    "skin-accent":             #4285F4,
    "skin-flat":               #F9F9F9,
    "skin-sidenav-item":       #4285F4,
    "skin-sidenav-item-hover": rgba($black-base, 0.05),
    "skin-gradient-start":     #ababab,
    "skin-gradient-end":       $white,
    "skin-mask-slight":        rgba($white, 0.5),
    "skin-mask-light":         rgba($white, 0.65),
    "skin-mask-strong":        rgba($white, 0.8),
    "skin-sn-child":           #4285F4,
    "skin-btn-primary":        #4285F4,
    "skin-btn-secondary":      #fc685f,
    "skin-btn-default":        #454545,
    "skin-text":               #424242
  ),
  "black": (
    "skin-primary-color":      #2AD1A3,
    "skin-navbar":             #222,
    "skin-footer-color":       #222,
    "skin-accent":             #2AD1A3,
    "skin-flat":               #222,
    "skin-sidenav-item":       #2AD1A3,
    "skin-sidenav-item-hover": #2AD1A3,
    "skin-gradient-start":     #000,
    "skin-gradient-end":       #616161,
    "skin-mask-slight":        rgba(0, 0, 0, 0.5),
    "skin-mask-light":         rgba(0, 0, 0, 0.65),
    "skin-mask-strong":        rgba(0, 0, 0, 0.8),
    "skin-sn-child":           #2AD1A3,
    "skin-btn-primary":        #2AD1A3,
    "skin-btn-secondary":      #0f478a,
    "skin-btn-default":        #222222,
    "skin-text":               $white
  ),
  "cyan": (
    "skin-primary-color":      #4fada7,
    "skin-navbar":             #114861,
    "skin-footer-color":       #114861,
    "skin-accent":             #f0b247,
    "skin-flat":               #24879d,
    "skin-sidenav-item":       rgba(29, 65, 82, 0.8),
    "skin-sidenav-item-hover": rgba(29, 65, 82, 0.8),
    "skin-gradient-start":     #114861,
    "skin-gradient-end":       #45b0c0,
    "skin-mask-slight":        rgba(21, 78, 96, 0.5),
    "skin-mask-light":         rgba(21, 78, 96, 0.65),
    "skin-mask-strong":        rgba(21, 78, 96, 0.8),
    "skin-sn-child":           #ffa742,
    "skin-btn-primary":        #ffa742,
    "skin-btn-secondary":      #45b0c0,
    "skin-btn-default":        #114861,
    "skin-text":               $white
  ),
  "mdb": (
    "skin-primary-color":      #33b5e5,
    "skin-navbar":             #243a51,
    "skin-footer-color":       #243a51,
    "skin-accent":             #33b5e5,
    "skin-flat":               #224562,
    "skin-sidenav-item":       rgba(72, 198, 255, 0.8),
    "skin-sidenav-item-hover": rgba(72, 198, 255, 0.8),
    "skin-gradient-start":     #0e2135,
    "skin-gradient-end":       #2c76b8,
    "skin-mask-slight":        rgba(13, 36, 60, 0.5),
    "skin-mask-light":         rgba(13, 36, 60, 0.65),
    "skin-mask-strong":        rgba(13, 36, 60, 0.8),
    "skin-sn-child":           #67d5ff,
    "skin-btn-primary":        #33b5e5,
    "skin-btn-secondary":      #0e3d67,
    "skin-btn-default":        #1a6398,
    "skin-text":               $white
  ),
  "deep-purple": (
    "skin-primary-color":      #d0637c,
    "skin-navbar":             #7f7e91,
    "skin-footer-color":       #7f7e91,
    "skin-accent":             #ac85f0,
    "skin-flat":               #372e5f,
    "skin-sidenav-item":       rgba(176, 139, 240, 0.5),
    "skin-sidenav-item-hover": rgba(176, 139, 240, 0.5),
    "skin-gradient-start":     #2e1f49,
    "skin-gradient-end":       #a47fe3,
    "skin-mask-slight":        rgba(36,  9,   56,  0.5),
    "skin-mask-light":         rgba(36,  9,   56,  0.65),
    "skin-mask-strong":        rgba(36,  9,   56,  0.88),
    "skin-sn-child":           #c9abfc,
    "skin-btn-primary":        #ac85f0,
    "skin-btn-secondary":      #6e4ca3,
    "skin-btn-default":        #372e5f,
    "skin-text":               $white
  ),
  "navy-blue": (
    "skin-primary-color":      #309e9e,
    "skin-navbar":             #353b50,
    "skin-footer-color":       #353b50,
    "skin-accent":             #40c6c6,
    "skin-flat":               #4c5678,
    "skin-sidenav-item":       rgba(37, 207, 207, 0.8),
    "skin-sidenav-item-hover": rgba(37, 207, 207, 0.8),
    "skin-gradient-start":     #222735,
    "skin-gradient-end":       #4f68a6,
    "skin-mask-slight":        rgba(14, 15,  32,  0.5),
    "skin-mask-light":         rgba(14, 15,  32,  0.65),
    "skin-mask-strong":        rgba(14, 15,  32,  0.8),
    "skin-sn-child":           #40c6c6,
    "skin-btn-primary":        #40c6c6,
    "skin-btn-secondary":      #7e51b4,
    "skin-btn-default":        #293756,
    "skin-text":               $white
  ),
  "pink": (
    "skin-primary-color":      #bd5d70,
    "skin-navbar":             #535466,
    "skin-footer-color":       #535466,
    "skin-accent":             #9fa0ae,
    "skin-flat":               #aa5077,
    "skin-sidenav-item":       rgba(0,   0,   0,   0.4),
    "skin-sidenav-item-hover": rgba(0,   0,   0,   0.4),
    "skin-gradient-start":     #812b5a,
    "skin-gradient-end":       #f28cc0,
    "skin-mask-slight":        rgba(152, 47,  88,  0.5),
    "skin-mask-light":         rgba(152, 47,  88,  0.65),
    "skin-mask-strong":        rgba(152, 47,  88,  0.8),
    "skin-sn-child":           #ffb0e6,
    "skin-btn-primary":        #601f39,
    "skin-btn-secondary":      #e7649d,
    "skin-btn-default":        #535466,
    "skin-text":               $white
  ),
  "indigo": (
    "skin-primary-color":      #7f82c5,
    "skin-navbar":             #9095aa,
    "skin-footer-color":       #9095aa,
    "skin-accent":             #fd668c,
    "skin-flat":               #404f9f,
    "skin-sidenav-item":       rgba(250, 80,  121, 0.8),
    "skin-sidenav-item-hover": rgba(250, 80,  121, 0.8),
    "skin-gradient-start":     #272d6e,
    "skin-gradient-end":       #90a8ff,
    "skin-mask-slight":        rgba(35,  65,  134, 0.5),
    "skin-mask-light":         rgba(35,  65,  134, 0.65),
    "skin-mask-strong":        rgba(35,  65,  134, 0.8),
    "skin-sn-child":           #ff89ac,
    "skin-btn-primary":        #fd668c,
    "skin-btn-secondary":      #3c61ae,
    "skin-btn-default":        #9095aa,
    "skin-text":               $white
  ),
  "light-blue": (
    "skin-primary-color":      #3d799c,
    "skin-navbar":             #3f5c80,
    "skin-footer-color":       #3f5c80,
    "skin-accent":             #b4c2c9,
    "skin-flat":               #6a9ed3,
    "skin-sidenav-item":       rgba(29,  54,  86,  0.6),
    "skin-sidenav-item-hover": rgba(29,  54,  86,  0.6),
    "skin-gradient-start":     #69adf4,
    "skin-gradient-end":       #69adf4,
    "skin-mask-slight":        rgba(87,  134, 180, 0.5),
    "skin-mask-light":         rgba(87,  134, 180, 0.65),
    "skin-mask-strong":        rgba(87,  134, 180, 0.8),
    "skin-sn-child":           #aadeff,
    "skin-btn-primary":        #ff4a67,
    "skin-btn-secondary":      #64c3f0,
    "skin-btn-default":        #2d486a,
    "skin-text":               $white
  ),
  "grey": (
    "skin-primary-color":      #2b2b2b,
    "skin-navbar":             #44474b,
    "skin-footer-color":       #44474b,
    "skin-accent":             #75c1f0,
    "skin-flat":               #a5a4a4,
    "skin-sidenav-item":       rgba(0,   0,   0,   0.4),
    "skin-sidenav-item-hover": rgba(0,   0,   0,   0.4),
    "skin-gradient-start":     #383838,
    "skin-gradient-end":       #a5a4a4,
    "skin-mask-slight":        rgba(121, 121, 121, 0.5),
    "skin-mask-light":         rgba(121, 121, 121, 0.65),
    "skin-mask-strong":        rgba(121, 121, 121, 0.8),
    "skin-sn-child":           #afdffc,
    "skin-btn-primary":        #46aded,
    "skin-btn-secondary":      #7d8488,
    "skin-btn-default":        #353637,
    "skin-text":               $white
  )
), $skins);

$skins-side-nav-hover-transition: all 0.3s linear !default;
$skins-border-color: rgba(153, 153, 153, 0.3) !default;
$skins-white-search-border-bottom: 1px solid $skins-border-color !default;

// Radio Buttons
$radio-fill-color:                    $primary-color !default;
$radio-empty-color:                   #5a5a5a !default;

// Dropdown
$dropdown-bg-color:                   $white !default;
$dropdown-hover-bg-color:             #eee !default;
$dropdown-color:                      $primary-color !default;
$dropdown-item-height:                40px !default;
$dropdown-menu-padding:               .5rem !default;
$dropdown-item-font-size:             $font-small !default;
$dropdown-item-disabled-color:        #868e96 !default;
$dropdown-item-transition:            all 0.1s linear !default;
$dropdown-menu-animated-animation-duration: .55s !default;
$dropdown-menu-animated-animation-timing-function: ease !default;

// Material select
$material-select-wrapper-input-dropdown-height: 2.9rem !default;
$material-select-wrapper-input-dropdown-line-height: $material-select-wrapper-input-dropdown-height !default;
$material-select-wrapper-input-dropdown-font-size: 1rem !default;
$material-select-wrapper-input-dropdown-margin: .94rem !default;
$material-select-wrapper-input-dropdown-disabled-color: rgba(0, 0, 0, .3) !default;
$material-select-wrapper-input-dropdown-disabled-border-bottom-color: $material-select-wrapper-input-dropdown-disabled-color !default;

$material-select-wrapper-search-wrap-py: 1rem !default;
$material-select-wrapper-search-wrap-mx: .7rem !default;
$material-select-wrapper-search-wrap-input-pb: .4rem !default;
$material-select-wrapper-span-caret-top: 1rem !default;
$material-select-wrapper-span-caret-font-size: .63rem !default;
$material-select-wrapper-span-caret-label-top: -.88rem !default;

$material-select-dropdown-li-img-my: .3rem !default;
$material-select-dropdown-li-img-mx: .75rem !default;
$material-select-dropdown-li-optgroup-selected-span: rgba(0, 0, 0, .7) !default;
$material-select-dropdown-li-optgroup-span: rgba(0, 0, 0, .4) !default;
$material-select-multiple-select-dropdown-height: .63rem !default;

$material-select-dropdown-content-min-width: 6.25rem !default;
$material-select-dropdown-content-max-height: 40.625rem !default;
$material-select-dropdown-content-li-color: $black !default;
$material-select-dropdown-content-li-line-height: 1.3rem !default;
$material-select-dropdown-content-li-span-font-size: .9rem !default;
$material-select-dropdown-content-li-span-padding: .5rem !default;

$material-select-colorful-dropdown-content-padding: .5rem !default;
$material-select-colorful-dropdown-content-li-active-color: $white !default;
$material-select-colorful-dropdown-content-li-hover-transition: all 0.1s linear !default;
$material-select-colorful-dropdown-content-li-hover-border-radius: $border-radius-base !default;

// SideNav Variables
$sidenav-breakpoint:                1440px !default;
$hide-sidenav:                      "only screen and (max-width: #{$sidenav-breakpoint})" !default;
$navbar-avatar-height:              35px   !default;
$navbar-double-small-padding:       4px    !default;
$navbar-double-font-size:           15px   !default;
$navbar-breadcrumb-dn-padding:      1rem   !default;
$navbar-button-collapse-left:       10px   !default;
$navbar-button-collapse-font-size:  1.5rem !default;
$navbar-button-collapse-fs-sm:      1.4rem !default;
$navbar-button-collapse-mx:         10px   !default;
$sidenav-padding-bottom:            3.75rem !default;
$sidenav-width:                     240px  !default;
$sidenav-background-color:          #2c2f34 !default;
$collapsible-hover-bgc:             rgba(0,0,0,.15) !default;
$collapsible-li-border-radius:      2px !default;
$collapsible-header-hover-bgc:      rgba(255, 255, 255, .15) !default;
$collapsible-header-active-bgc:     $collapsible-header-hover-bgc !default;
$sidenav-collapsible-a-font-size:   .8rem !default;
$sidenav-collapsible-a-height:       36px !default;
$sidenav-collapsible-a-line-height: $sidenav-collapsible-a-height !default;
$sidenav-fa-margin-right:           13px !default;
$sidenav-collapsible-body-a-pl:     47px !default;
$sidenav-collapsible-body-a-bgc:    rgba(0,0,0,.15) !default;
$sidenav-a-font-size:               1rem !default;
$sidenav-a-height:                  56px !default;
$sidenav-a-line-height:             $sidenav-a-height !default;
$sidenav-padding-left:              20px !default;

$sidenav-logo-wrapper-height:      140px !default;
$sidenav-logo-wrapper-width:       240px !default;
$sidenav-logo-wrapper-img-px: 50px !default;
$sidenav-logo-wrapper-img-py: 20% !default;
$sidenav-logo-wrapper-medium-img-height: 80px !default;
$sidenav-logo-wrapper-medium-img-py: 7% !default;

$sidenav-sn-avatar-wrapper-px: 33% !default;
$sidenav-sn-avatar-wrapper-max-width: 90px !default;
$sidenav-sn-avatar-wrapper-py: 10% !default;
$sidenav-sn-avatar-wrapper-medium-px: 40% !default;
$sidenav-sn-avatar-wrapper-medium-max-width: 50px !default;

$sidenav-bg-width:                 240px !default;
$sidenav-collapsible-mt: 1rem !default;
$sidenav-rotate-icon-top: .8rem !default;
$sidenav-rotate-icon-mr: 1.25rem !default;
$sidenav-logo-wrapper-border-bottom-color: rgba(255, 255, 255, .65) !default;
$sidenav-ad-avatar-wrapper-img-max-width: 3.75rem !default;
$sidenav-ad-avatar-wrapper-img-padding: 1.25rem .63rem !default;
$sidenav-ad-avatar-wrapper-p-font-size: .94rem !default;
$sidenav-ad-avatar-wrapper-p-padding: 1.25rem !default;
$sidenav-about-padding: 1rem !default;
$sidenav-social-li-padding: .6rem !default;
$sidenav-social-fa-font-size: .9rem !default;
$sidenav-social-fa-color: #dbe4e7 !default;
$sidenav-social-fa-color-hover: #AFC4CB !default;
$sidenav-social-fa-transition: .3s !default;
$sidenav-search-form-input-padding-left: 1.88rem !default;

$sidenav-light-bgc:                              #e5e5e5                 !default;
$sidenav-light-color:                            #555                    !default;
$sidenav-light-border-bottom-color:              rgba(153, 153, 153, .3) !default;
$sidenav-light-collapsible-body-a-bgc:           rgba($black-base, .1)         !default;
$sidenav-light-collapsible-header-hover-bgc:     rgba($black-base, 0.05)      !default;
$sidenav-light-collapsible-header-active-color:  #4285F4                 !default;

$drag-target-width:                              10px                    !default;
$sidenav-overlay:                                rgba($black-base, .5)        !default;

// LIGHT BOX
$pswp__show-hide-transition-duration: 333ms !default;
$pswp__controls-transition-duration: 333ms !default;
$pswp__background-color: #000 !default;
$pswp__placeholder-color: #222 !default;
$pswp__box-sizing-border-box: true !default; // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
$pswp__root-z-index: 1500 !default;
$pswp__assets-path: "" !default; // path to skin assets folder (preloader, PNG and SVG sprite)
$pswp__error-text-color: #CCC !default; // "Image not loaded" text color
$pswp__include-minimal-style: true !default;
$mdb-lightbox-figure-img-transition: opacity .4s !default;
$mdb-lightbox-figure-img-hover-opacity: .8 !default;
$mdb-lightbox-no-margin-padding: .5rem !default;
$pswp__button-background: url(#{$image-path}/lightbox/default-skin.png) !default;
$pswp__button-background-image: url(#{$image-path}/lightbox/default-skin.svg) !default;
$pswp__preloader__icn-background: url(#{$image-path}/lightbox/preloader.gif) !default;

// Date Picker
$datepicker-weekday-bg: darken($primary-color, 7%) !default;
$datepicker-date-bg: $primary-color !default;
$datepicker-year: rgba(255, 255, 255, .4) !default;
$datepicker-focus: rgba(0,0,0, .05) !default;
$datepicker-selected: $primary-color !default;
$datepicker-selected-outfocus: desaturate(lighten($primary-color, 35%), 15%) !default;

// Default time picker
$timepicker-pickerbox-breakpoint: 40.125em !default;
$timepicker-pickerlistitem-breakpoint: 46.75em !default;
// Default time & date picker
$pickerframe-wrap-box-breakpoint: 28.875em !default;
$pickerframe-mb-breakpoint: 40.125em !default;
$pickerframe-pos-breakpoint: 35.875em !default;
// Default date picker
$pickerweekday-padb-breakpoint: 33.875em !default;

// Steppers
$stepper-li-a-padding: 1.5rem !default;
$stepper-li-a-font-size: .88rem !default;
$stepper-li-a-circle-color: $white !default;
$stepper-li-a-circle-border-radius: $border-radius-circle !default;
$stepper-li-a-circle-bg: rgba(0,0,0,.38) !default;
$stepper-li-a-circle-width: $stepper-li-a-padding !default;
$stepper-li-a-circle-height: $stepper-li-a-circle-width !default;
$stepper-li-a-circle-line-height: $stepper-li-a-circle-width !default;
$stepper-li-a-circle-mr: .5rem !default;
$stepper-li-a-label-color: rgba(0,0,0,.87) !default;

$stepper-horizontal-li-transition: .5s !default;
$stepper-horizontal-li-a-label-mt: .63rem !default;
$stepper-horizontal-li-after-margin: .5rem !default;
$stepper-horizontal-li-after-height: 1px !default;
$stepper-horizontal-li-after-bgc: rgba(0,0,0,.1) !default;
$stepper-horizontal-li-hover-bg: #ddd !default;
$stepper-horizontal-breakpoint: 767px !default;
$stepper-horizontal-small-li-a-label-mt: .2rem !default;
$stepper-horizontal-small-li-after-width: $stepper-horizontal-li-after-height !default;
$stepper-horizontal-small-li-after-height: calc(100% - 40px) !default;
$stepper-horizontal-small-li-after-left: 2.19rem !default;
$stepper-horizontal-small-li-after-top: 3.75rem !default;

$stepper-vertical-li-a-label-mt: .2rem !default;
$stepper-vertical-li-step-content-ml: 3.13rem !default;
$stepper-vertical-li-step-content-padding: .94rem !default;
$stepper-vertical-li-step-content-p-font-size: $stepper-li-a-font-size !default;
$stepper-vertical-li-after-width: $stepper-horizontal-li-after-height !default;
$stepper-vertical-li-after-height: $stepper-horizontal-small-li-after-height !default;
$stepper-vertical-li-after-left: $stepper-horizontal-small-li-after-left !default;
$stepper-vertical-li-after-top: 3.44rem !default;
$stepper-vertical-li-after-bgc: $stepper-horizontal-li-after-bgc !default;

// Ecommerce components
$collection-card-stripe-bottom: 3rem !default;
$collection-card-stripe-padding: 1.2rem !default;
$collection-card-stripe-dark-bgc: $material-select-dropdown-li-optgroup-selected-span !default;
$collection-card-stripe-dark-a-p-color: $grey-lighten-3 !default;
$collection-card-stripe-light-bgc: rgba(255, 255, 255, .7) !default;
$collection-card-stripe-light-a-p-color: $grey-darken-3 !default;
$collection-card-stripe-letter-spacing: .25rem !default;

$ecommerce-rating-color: #ffa000 !default;
$ecommerce-card-footer-discount-color: #9e9e9e !default;
$ecommerce-card-footer-a-color: #757575 !default;
$ecommerce-card-footer-a-hover-transition: .4s !default;
$ecommerce-card-footer-a-hover-color: #d50000 !default;
$ecommerce-card-footer-a-active-color: $ecommerce-card-footer-a-hover-color !default;
$ecommerce-product-table-btn-sm-py: .3rem !default;
$ecommerce-product-table-btn-sm-px: .6rem !default;

// Toasts
$toast-message-a-hover-color:       #cccccc !default;
$toast-close-button-right:          -0.3em !default;
$toast-close-button-top:            $toast-close-button-right !default;
$toast-close-button-font-size:      1.25rem !default;
$toast-close-button-text-shadow:    0 1px 0 !default;
$toast-close-button-opacity:        .8 !default;
$toast-close-button-filter:         alpha(opacity=80) !default;
$toast-close-button-hover-opacity:  .4 !default;
$toast-close-button-hover-filter:   alpha(opacity=40) !default;
$toast-top-bottom-left-right-size:  12px !default;
$toast-container-margin:            6px !default;
$toast-container-py:                15px !default;
$toast-container-pr:                $toast-container-py !default;
$toast-container-pl:                50px !default;
$toast-container-width:             300px !default;
$toast-container-opacity:           .95 !default;
$toast-container-filter:            alpha(opacity=95) !default;
$toast-container-hover-transition:  .45s !default;
$toast-container-hover-opacity:     1 !default;
$toast-container-hover-filter:      alpha(opacity=100) !default;
$toast-container-top-full-width:    96% !default;
$toast-bgc:                         #030303 !default;
$toast-progress-height:             4px !default;
$toast-progress-opacity:            $toast-close-button-hover-opacity !default;
$toast-progress-filter:             $toast-close-button-hover-filter !default;

// Magazine section
$magazine-single-news-border-bottom: #e0e0e0 !default;

// Pricing section
$pricing-card-option-padding: 2.5rem !default;
$pricing-card-price-padding-top: 3.94rem !default;
$pricing-card-number-font-size: 5rem !default;
$pricing-card-number-padding: 1.5rem !default;
$pricing-card-number-before-font-size: 1.88rem !default;
$pricing-card-number-before-margin-left: -1.2rem !default;
$pricing-card-number-before-margin-top: .7rem !default;
$pricing-card-number-after-font-size: $pricing-card-number-before-font-size !default;
$pricing-card-number-after-margin-top: 3rem !default;
$pricing-card-version-padding: 1rem !default;
$pricing-card-version-bgc: $material-select-wrapper-input-dropdown-disabled-color !default;
$pricing-card-striped-padding: 1rem !default;
$pricing-card-striped-li-border-bottom-color: rgba(153, 153, 153, 0.298039) !default;
$pricing-card-striped-li-margin-bottom: 1rem !default;
$pricing-card-green-striped-li-border-color: #33c28a !default;
$pricing-card-orange-striped-li-border-color: #e97d63 !default;
$pricing-card-purple-striped-li-border-color: #963c94 !default;
$pricing-card-dark-bgc: #28283f !default;
$pricing-card-circle-border-color: #e0e0e0 !default;
$pricing-card-circle-height: 7.5rem !default;
$pricing-card-circle-width: $pricing-card-circle-height !default;
$pricing-card-circle-border-radius: $border-radius-circle !default;
$pricing-card-circle-icon-font-size: 4rem !default;

// Team section
$team-section-avatar-max-width: 200px !default;
$team-section-avatar-circle-max-width: 150px !default;

// Testimonials section
$testimonial-carousel-control-top: 50% !default;
$testimonial-carousel-control-transform: translateY(-35%) !default;
$testimonial-carousel-control-font-size: 5rem !default;
$testimonial-carousel-control-color: rgba($black-base,.6) !default;
$testimonial-carousel-control-hover-transition: .4s !default;
$testimonial-carousel-control-hover-color: $black-base !default;
$testimonial-carousel-control-mx: 6% !default;
$testimonial-carousel-avatar-max-width: 170px !default;
$testimonial-carousel-mx: 15% !default;

// Contact section
$contact-section-form-btn-floating-bottom: 3rem !default;
$contact-section-bgc: #103f6d !default;
$contact-section-fa-color: #6b89a5 !default;

// Templates section
$fixed-sn-double-nav-main-footer-pl: 240px !default;
$fixed-sn-double-nav-main-pt: 5.5rem !default;
$fixed-sn-double-nav-main-small-mx: 2% !default;
$fixed-sn-double-nav-main-medium-mx: 5% !default;
$fixed-sn-double-nav-main-large-mx: 6% !default;

$hidden-sn-main-pt: $fixed-sn-double-nav-main-pt !default;
$hidden-sn-main-btn-collapse-font-size: 1.4rem !default;
$hidden-sn-main-btn-collapse-mr: .625rem !default;
$hidden-sn-main-btn-collapse-ml: -.375rem !default;

// Carousel
$carousel-thumbnails-mb: 5rem !default;
$carousel-thumbnails-indicators-mb: -4.69rem !default;
$carousel-thumbnails-sm-mb: 3.75rem !default;
$carousel-thumbnails-indicators-sm-mb: -3.44rem !default;
$carousel-thumbnails-indicators-li-width: 6.25rem !default;
$carousel-thumbnails-indicators-li-max-width: $carousel-thumbnails-indicators-li-width !default;
$carousel-thumbnails-indicators-active-opacity: .5 !default;

$carousel-multi-item-mb: 5rem !default;
$carousel-multi-item-indicators-mb: -2em !default;
$carousel-multi-item-indicators-li-height: 1.25rem !default;
$carousel-multi-item-indicators-li-width: $carousel-multi-item-indicators-li-height !default;
$carousel-multi-item-indicators-li-max-width: $carousel-multi-item-indicators-li-width !default;
$carousel-multi-item-indicators-li-mb: -3.75rem !default;
$carousel-multi-item-indicators-active-height: 1.56rem !default;
$carousel-multi-item-indicators-active-width: $carousel-multi-item-indicators-active-height !default;
$carousel-multi-item-indicators-active-max-width: $carousel-multi-item-indicators-active-width !default;
$carousel-multi-item-indicators-active-border-radius: $border-radius-circle !default;
$carousel-multi-item-controls-top-mb: 1.88rem !default;
$carousel-multi-item-card-cascade-narrower-mt: 1.25rem !default;
$carousel-multi-item-card-cascade-narrower-mb: .3rem !default;
$carousel-multi-item-medium-screen-mb: -1.88rem !default;

// Advanced table with checkboxes
$advanced-table-a-mr: 1rem !default;
$advanced-table-fieldset-label-height: .94rem !default;

// Checkbox
$base-radio-checkbox-label-pl: 2.19rem !default;
$base-radio-checkbox-label-height: 1.57rem !default;
$base-radio-checkbox-label-line-height: $base-radio-checkbox-label-height !default;
$base-radio-checkbox-label-font-size: 1rem !default;