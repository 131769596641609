/* stylelint-disable block-no-empty, no-descending-specificity, font-family-no-missing-generic-family-keyword */

.calendar-dropdown {
	width: 100%;

	.dropdown-toggle:not([disabled]):not(.disabled),
	&.show .dropdown-toggle:not([disabled]):not(.disabled) {
		&:active,
		&:hover,
		&:focus {
			background-color: #fff !important;
		}
	}

	.dropdown-toggle.btn-secondary {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		padding: 0 15px;
		border: 1px solid #ababab !important;
		margin: 0;
		background-color: #fff !important;
		border-radius: 5px !important;
		box-shadow: none !important;
		color: #5d5c59 !important;
		line-height: 54px;
		text-align: left;
		text-transform: inherit;

		@include fluid-type-default(12px, 18px);

		&:after {
			display: none;
		}

		.caret-wrapper {
			display: flex;
			width: 30px;
			height: 30px;
			align-items: center;
			justify-content: center;
			background-image: url(#{$imageBaseUrl}/icons/icon-background-circle.svg);
			background-position: center;
			background-repeat: no-repeat;

			.caret {
				display: block;
				width: 18px;
				height: 18px;
				background-image: url(#{$imageBaseUrl}/icons/icon-calendar-gray.svg);
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}

	&.show .dropdown-toggle {
		background-color: #fff !important;
		border-color: #3d852c !important;

		.caret {
			background-image: url(#{$imageBaseUrl}/icons/icon-calendar-green.svg);
		}
	}

	.dropdown-menu {
		right: 0;
		width: 360px;
		padding: 10px 5px 25px;
		border: none;
		margin: 0 auto;
		border-radius: 0;

		.react-calendar {
			border: 0 none;

			.react-calendar__navigation {
				.react-calendar__navigation__label {
					background-color: #fff;
					color: #000;
					font-family: 'Lato';
					font-size: 20px;
					font-weight: bold;
				}

				.react-calendar__navigation__arrow {
					background-color: #fff;

					.arrow {
						display: inline-block;
						width: 30px;
						height: 30px;
						background: url(#{$imageBaseUrl}/icons/icon-caret.svg) center no-repeat;
					}

					&[disabled] .arrow {
						background-image: url(#{$imageBaseUrl}/icons/icon-caret-gray.svg);
					}

					&.react-calendar__navigation__prev-button .arrow {
						transform: rotate(-90deg);
					}

					&.react-calendar__navigation__next-button .arrow {
						transform: rotate(90deg);
					}
				}
			}

			// month view
			.react-calendar__month-view {
				// all days
				.react-calendar__tile {
					padding: 0 10px;
					border-color: #c1c1c1;
					border-left-width: 1px;
					border-style: solid;
					border-top-width: 1px;
					font-family: 'Lato';
					font-size: 20px;
					font-weight: bold;
					line-height: 48px;

					&:nth-child(7n) {
						border-right-width: 1px;
					}

					&:nth-last-child(-n + 7) {
						border-bottom-width: 1px;
					}

					&:hover,
					&:focus {
						background-color: #f4b047;
						color: #fff;
						font-size: 22px;
					}

					// disabled days
					&[disabled] {
						background-color: #fff;
						color: graytext;
					}
				}

				// weekend
				.react-calendar__month-view__days__day--weekend {
					color: #000;
				}

				// selected day
				.react-calendar__tile--active {
					background-color: #fff;
					color: #3d852c;
					font-size: 22px;
				}

				// days of neighboring months
				.react-calendar__month-view__days__day--neighboringMonth {
					background-color: #fff;

					> * {
						display: none;
					}
				}

				// day of week title
				.react-calendar__month-view__weekdays__weekday {
					font-family: 'Lato';
					font-size: 14px;
					font-weight: normal;
					text-transform: none;

					> * {
						cursor: default;
						text-decoration: none;
					}
				}
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		width: auto;

		.dropdown-toggle {
			width: 115px;
		}

		.dropdown-menu {
			width: 430px;
			padding: 20px 40px 40px;
			margin: 0;
		}

		&.show {
			.dropdown-toggle {
				border-color: #ababab !important;
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.dropdown-toggle {
			width: 130px;
		}
	}
}
