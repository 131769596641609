/* stylelint-disable font-family-no-missing-generic-family-keyword */
@import '../../vars/colors';
@import './md_form';

.md-form {
	margin-top: 0;

	label {
		top: 1.6rem;
		padding-right: 8px;
		padding-left: 5px;
		margin-right: 10px;
		margin-left: 10px;
		color: $black;
		font-size: 16px;

		&.active {
			background-color: $deep-white;
			font-size: 16px;

			/* -webkit-transform: translateY(-100%); */

			/* -ms-transform: translateY(-100%); */
			transform: translateY(-110%);
		}
	}

	input {
		box-shadow: none;
	}

	.form-control {
		padding: 1rem 0 .55rem;
		margin: 0 0 .5rem;
		background-color: transparent;
		background-image: none;
		border-radius: 0;

		&:focus {
			border-top: 2px solid $dinova-green !important;
			border-right: 2px solid $dinova-green !important;
			border-bottom: 2px solid $dinova-green !important;
			border-left: 2px solid $dinova-green !important;
			background-color: $white;
			border-color: $dinova-green !important;
			box-shadow: none;
			box-shadow: 0 0 0 0 $dinova-green !important;
		}
	}
}

.Di_HasError {
	padding-top: 0;
	margin-top: -15px;
	// margin-left: 10px; TODO break connect to points and edit payment forms maybe more
	color: $danger-red;
	font-size: 90%;

	.suggestions li:not(.suggestion-active) {
		color: $black-text;
	}

	.md-form {
		label {
			.svg-inline--fa {
				margin-right: 8px;
				color: $danger-red;
				font-size: 1.5em;
				vertical-align: middle;
			}

			&.active {
				padding-left: 10px;
			}
		}

		input {
			+ label {
				&:before {
					display: none;
					color: $danger-red;
					content: '\f06a';
					font-family: 'Font Awesome 5 Light';
					opacity: 0;
				}
			}
		}

		input,
		input:focus {
			border-color: $danger-red !important;
			border-width: 2px;
		}
	}
}

.textbox-input.cross-mark .md-form input + label:after {
	content: ' \271D\00FE0E' !important;
}
