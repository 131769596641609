.dashboard-page .Di_PageLimit {
	min-width: 280px;
	padding-right: 0px;
	padding-left: 0px;
}

@media (min-width: 768px) {
	.dashboard-page .Di_PageLimit {
		width: 940px;
	}
}

@media (min-width: 1024px) {
	.dashboard-page .Di_PageLimit {
		width: 940px;

		.card-body {
			display: flex;
			flex-flow: row nowrap;
			align-items: flex-start;
			justify-content: center;
		}
	}
}
