// TODO: Global Style
.btn.btn-warning {
	padding: .5rem 0;
	border: solid $orange-peel 2px;
	background-color: $white !important;
	color: $black-text !important;
	text-transform: none;

	@include fluid-type-default(14px, 18px);

	// Not Disabled
	&:not([disabled]):not(.disabled) {
		// Active
		&:active,
		&.active {
			background-color: $white !important;
			border-color: $orange-peel !important;
		}

		// Hover, Focus
		&:hover,
		&:focus {
			background-color: $white !important;
			border-color: $orange-peel !important;
		}
	}
}
