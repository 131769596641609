.recipient-form {
	.title {
		margin: 90px 0 30px;
	}

	.note {
		display: block;
		color: $charcoal;
		font: 20px/24px 'Lato', sans-serif;
	}

	.field {
		margin-top: 50px;

		.md-form,
		.md-form input {
			margin-right: 0;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.title {
			margin: 40px 0 25px;
		}

		.field {
			margin-top: 25px;
		}
	}
}
