@import './page_limit/main';
@import './header/main';
@import './banners/main';
@import './accordion/main';
@import './logo/main';
@import './input/main';
@import './link-back/main';
@import './alert/main';

/* Global Selectors */

/* Layout */
.center {
	text-align: center;
}

/* Flex Layout */
.flex {
	display: flex;
}

.flex.row-wrap {
	flex-wrap: wrap;
}

.column {
	display: flex;
	flex-direction: column;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.justify-around {
	justify-content: space-around;
}

.justify-between {
	justify-content: space-between;
}

.justify-start {
	justify-content: flex-start;
}

.justify-center {
	justify-content: center;
}

/* Fonts */
.normal-text {
	text-transform: none !important;
}

/* Hidden */
.hidden {
	display: none;
	height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	opacity: 0;
	visibility: hidden;
}

span.required {
	margin-left: 1px;
	color: $danger-red;
}

.sm-hidden {
	@media screen and (max-width: 767px) {
		display: none;
		height: 0 !important;
		padding: 0 !important;
		margin: 0 !important;
		opacity: 0;
		visibility: hidden;
	}
}

.green-text {
	color: $dinova-green !important;
}

:not(i).arrow {
	display: inline-block;
	padding: 4px;
	border: solid black;
	border-width: 0 2px 2px 0;
	transition: transform ease .3s;

	&.green {
		background-color: transparent !important;
		border-color: $dinova-green;
	}

	&.right {
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	&.left {
		-webkit-transform: rotate(135deg);
		transform: rotate(135deg);
	}

	&.up {
		-webkit-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}

	&.down {
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
