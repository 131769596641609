.helpfaq {
	.ads {
		.ad-item {
			display: none;

			&:first-child {
				display: block;
			}
		}
	}
}
