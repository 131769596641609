// Green
$dinova-green: #1c871c; // confirmed -
$pale-dinova-green: #edf5ed;
$active-dinova-green: #199119;
$loading-green: #3dae2b;
$deep-green: #186860;
$forest-green: #1e8720;
$green-opacity-8: rgba(151, 212, 143, .8);
$green-opacity-9: rgba(151, 212, 143, .9);

// White
$white: #fff; // confirmed
$white-opacity-9: rgba(255, 255, 255, .9);
$white-opacity-95: rgba(255, 255, 255, .95);
$deep-white: #fdfdfd;

// Black
$black-text: #4d4c48; // confirmed - copy text
$charcoal: #4d4c4b;
$black: #000; // confirmed - label text
$black-15: rgba(0, 0, 0, .15);
$black-18: rgba(0, 0, 0, .18);
$black-opacity-19: rgba(0, 0, 0, .19);
$black-opactiy-5: rgba(0, 0, 0, .5);
$black-opacity-3: rgba(0, 0, 0, .3);
$black-opacity-2: rgba(0, 0, 0, .2);

// Blue
$blue: rgba(136, 183, 213, 0);

// Red
$red: red;
$required-red: #ee5d38;
$danger-red: #e10600;
$loading-red: #e10600;

// Grey
$checkbox-border-grey: #5a5a5a;
$tint-grey: rgba(240, 240, 240, .8);
$grey-text: #c0c0c0;
$grey-active-text: #94989a;
$foggy-grey: #e6e6e6;
$grey: #ccc; // confirmed - lightest text
$input-border-grey: #aaa; // confirmed - med-light-grey text
$light-charcoal: #666; // confirmed - med  text
$super-light-grey: #f7f7f7;
$light-grey: #fafbfb; // confirmed - list background light
$accordion-title-grey: #f6f6f6;
$accordion-title-light-grey: #fafbfb;
$medium-grey: #f4f4f4;
$pink-grey: #d6d6d6;
$popup-nav-border-grey: #d1d1d1;
$input-checked-background-grey: #eaeaea;
$dnaGray: #d1d3d4;
$dnaGrayLight: #fafbfb;
$dnaGreenPri: #88b443;
$searchTopBarBg: #f6f6f6;

// NOTE: NOT USED
$list-background-dark: #f5f5f3; // confirmed - list background light
$list-expanded-background-light: #ececec; // confirmed - list background light
$list-expanded-background-dark: #dcdcdc; // confirmed - list background light
// End NOT USED
$med-light-grey: #cdcdcd;
$deep-grey: #333;
$deeper-grey: #444;
$deepest-grey: #999;
$grey-yellow: #5a5955;

// Yellow
$loading-yellow: #ffc600;

// Orange
$loading-orange: #ff8200;
$fire-orange: #ec5533;
$orange-peel: #ffac27;
$sunset-orange: #fbab2b;
