.endorse {
	display: flex;
	flex-direction: column;

	p.text {
		margin: 0px;

		&:first-child {
			@include fluid-type-default(14px, 16px);
		}
	}

	p.text.disclaimer {
		margin: 0 0 20px;
	}

	div.flex {
		margin: 1em 0;
	}
}

.has-count {
	.endorse {
		p.text:not(.disclaimer) {
			margin: 1em 0 0;
		}
	}
}

@media screen and (min-width: 768px) {
	.endorse {
		div.flex {
			margin: 0;

			p.text:not(.disclaimer) {
				margin: 0 1em;
			}
		}
	}
}
