.profile-section-list {
	> *:not(:last-child) {
		margin-bottom: 35px;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		> *:not(:last-child) {
			margin-bottom: 28px;
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		> *:not(:last-child) {
			margin-bottom: 25px;
		}
	}
}
