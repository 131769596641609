@import './ie-style';

.Di_LogoBoxLeft {
	display: flex;
	flex: 1 0 0;
	flex-direction: column;
	align-items: center;

	img {
		height: 35px;
	}

	.welcome-phrase,
	.welcome {
		margin: 5px 0 0;
		color: $grey-yellow;
		font-size: 14px;
		font-weight: 500;
		line-height: 24px;
	}
}

.center {
	.Di_LogoBoxLeft {
		justify-content: center;
	}
}

@media (min-width: 768px) {
	.Di_LogoBoxLeft {
		.logo-link {
			img {
				height: 36px;
				margin-top: 0px;
				margin-left: 0;
			}
		}

		.welcome-phrase,
		.welcome {
			margin-top: 12px;
			font-size: 20px;
			line-height: 20px;
		}
	}
}

@media (min-width: 1024px) {
	.Di_LogoBoxWideTall {
		padding-top: 20px;
	}

	.Di_LogoBoxLeft {
		flex-direction: row;
		justify-content: flex-start;

		.logo-link {
			img {
				height: 55px;
				margin-top: 5px;
			}
		}

		.welcome-phrase,
		.welcome {
			margin-left: 15px;
			font-size: 20px;
		}
	}
}
