.payment-not-connected {
	text-align: center;

	// only for mobile
	@include media-breakpoint-down(md) {
		.btn-wrapper {
			display: inline-block;
			width: 166px;

			.btn {
				margin: 0;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.title {
			max-width: 60%;
			margin: 0 auto;
		}
	}
}
