.title {
	margin-bottom: 15px;
	color: $black;
	font-weight: bold;
	text-align: center;

	&.text-normal {
		text-transform: none;
	}
}

// sizes
h1.title {
	@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 15px, 20px);
}

h2.title {
	@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 16px, 22px);
}

h3.title {
	@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 12px, 16px);
}

// for table and up
@include media-breakpoint-up(md) {
	// sizes
	h1.title,
	h2.title {
		@include fluid-type(map-get($grid-breakpoints, md), map-get($grid-breakpoints, lg), 24px, 28px);
	}

	h3.title {
		@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 20px, 24px);
	}
}

// for desktop
@include media-breakpoint-up(lg) {
	// sizes
	h1.title {
		@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 20px, 24px);
	}

	h2.title {
		@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 18px, 22px);
	}

	h3.title {
		@include fluid-type(map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xl), 14px, 18px);
	}
}
