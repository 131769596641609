.ie {
	.search-tab.catering-search-tab {
		.location-input-wrapper.error {
			.search-dropdown {
				.search-input {
					padding-top: 0px;
					padding-bottom: 0px;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.search-tab.catering-search-tab {
			.search-group {
				[class*='input-wrapper']:nth-child(2) input {
					padding: 11px 15px;
				}

				.search-btn-wrapper {
					.search-btn {
						padding: 5px 0;
					}
				}
			}
		}
	}
}
