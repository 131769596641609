.search-dropdown {
	position: relative;
	width: 1%;
	flex: 1 1 auto;
	margin-bottom: 0;

	.caret-wrapper {
		position: absolute;
		top: 50%;
		right: 15px;
		display: flex;
		width: 30px;
		height: 30px;
		align-items: center;
		justify-content: center;
		background-image: url(#{$imageBaseUrl}/icons/icon-background-circle.svg);
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
		transform: translateY(-50%);

		.caret {
			display: block;
			width: 20px;
			height: 20px;
			background-image: url(#{$imageBaseUrl}/icons/icon-current-location-black-outline.svg);
			background-position: center;
			background-repeat: no-repeat;
			transform: rotate(180deg);
		}
	}

	.dropdown-list {
		position: absolute;
		right: 0;
		left: 0;
		display: none;
		width: 100%;
		padding: 0px 12px;
		background-color: $white;
		border-color: $input-border-grey;
		border-style: solid;
		border-width: 0 1px 1px;

		.nearby-btn {
			padding: 0 10px 0 40px;
			border: solid 1px #4ca353 !important;
			background: url(#{$imageBaseUrl}/icons/icon-current-location-green-outline.svg) 8px center no-repeat !important;
			background-size: 20px !important;
			box-shadow: none;
			color: #4ca353 !important;
			font-size: 18px;
			line-height: 44px;
			text-align: left;
			text-transform: none;

			@include fluid-type-default(12px, 18px);

			&:active {
				border-color: #4ca353 !important;
			}
		}
	}

	&.show {
		.caret-wrapper .caret {
			background-image: url(#{$imageBaseUrl}/icons/icon-current-location-green-outline.svg);
		}

		.dropdown-list {
			z-index: 5;
			display: block !important;
		}
	}
}
