.no-catering-service {
	position: relative;
	// padding: 1em 2.5em 2.5em;

	@media screen and (min-width: 768px) {
		display: flex;

		.splash {
			position: absolute;
			top: -25px;
			left: 0px;
			width: 50%;
			height: calc(100% + 25px);
			background: url(#{$imageBaseUrl}/graphics/desert.png) 0px center no-repeat;
			background-size: cover;
		}

		.no-catering-service-content-wrapper {
			width: 50%;
			padding-left: 2.5em;
			margin-left: 50%;
		}
	}

	@media screen and (min-width: 1024px) {
		.no-catering-service-content-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.basic-modal-footer {
				margin-bottom: 3em;

				.horizontal-nav {
					width: 100%;
				}
			}
		}
	}

	.btn.btn-primary,
	.btn.btn-secondary {
		width: 100%;
	}

	.btn.btn-primary {
		margin-bottom: 25px;
	}
}
