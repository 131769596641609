.map-card {
	display: flex;
	width: 364px;
	flex-direction: column;
	flex-shrink: 0;
	align-items: center;
	padding: 10px;
	border: 1px solid #3d852c;
	background-color: #fff;
	box-shadow: 3px 4px 10px 0 rgba(#000, .5);
	font-weight: 400;
	text-decoration: none !important;

	.img {
		width: 348px;
		height: 152px;
		object-fit: cover;
		object-position: center;
	}

	.info {
		display: flex;
		width: 100%;
		flex-grow: 1;
		justify-content: space-between;
		margin-top: 8px;

		.restaurant-info {
			display: flex;
			flex-direction: column;
			color: #fff;

			> * {
				font-size: 14px;
				line-height: 1;
			}

			.name {
				margin-bottom: 5px;
				color: #1c871c;
				font-weight: 500;
			}

			.cuisine {
				color: #5d5c59;
				line-height: 18px;
			}
		}

		.distance {
			color: #000;
			font-size: 14px;
			font-weight: 500;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		width: 356px;
		padding: 10px;

		.img {
			width: 344px;
			height: 150px;
		}

		.info {
			margin-top: 10px;

			.restaurant-info {
				.name {
					font-size: 14px;
				}
			}

			.distance {
				font-size: 12px;
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		width: 472px;
		padding: 10px;

		.img {
			width: 460px;
			height: 200px;
		}

		.info {
			margin-top: 15px;

			.restaurant-info {
				.name {
					font-size: 16px;
				}
			}

			.distance {
				font-size: 14px;
			}
		}
	}
}
