.search-top-bar {
	background-color: $searchTopBarBg;

	.inner-wrapper {
		.business-search-tab {
			padding: 10px;

			.near-input-wrapper {
				margin: 0;
			}

			.search-btn-wrapper {
				display: none;
			}
		}

		.search-error-message {
			padding: 15px;
			border: 2px solid #e37959;
			margin: 0 10px 10px;
			background-color: $white;
			color: #e37959;
			font: 16px/20px 'Lato', sans-serif;
			font-weight: bold !important;
		}

		.bottom-bar {
			display: flex;
			// min-height: 50px;
			// padding: 15px;
			background-color: $white;

			.filter-btn {
				display: flex;
				align-items: flex-start;
				font-size: 18px;
				font-weight: bold;
				text-decoration: none;

				.filter-count {
					width: 18px;
					height: 18px;
					background-color: $dinova-green;
					border-radius: 50%;
					color: $white;
					font-size: 70%;
					text-align: center;
				}
			}

			.filters {
				display: flex;
				align-items: center;

				> *:not(:last-child) {
					margin-right: 10px;
				}

				> .label {
					font-size: 18px;
					font-weight: bold;
				}
			}

			.toggle-list-btn {
				display: flex;
				align-items: center;
				margin-left: auto;
				text-decoration: none;

				.icon {
					height: 14px;
					margin-right: 5px;
				}

				.map-icon {
					height: 24px;
				}

				.label {
					font-size: 18px;
					font-weight: bold;
					line-height: 1;
				}
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		padding: 15px 25px;

		.inner-wrapper {
			max-width: 1024px;
			margin: 0 auto;

			.business-search-tab {
				padding: 0;

				.search-btn-wrapper {
					display: flex;
				}
			}

			.input-label {
				padding: 0 0 0 9px;
			}

			.search-error-message {
				margin: 10px 0 0;
			}

			.bottom-bar {
				display: flex;
				justify-content: space-between;
				padding: 10px 0 0;
				background-color: $searchTopBarBg;
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		// margin: 15px 15px 0;
	}
}
