.dropdown {
	position: relative;
	overflow: visible;
	width: 100%;
	cursor: pointer;

	.dropdown-list-wrapper {
		position: absolute;
		z-index: 2000;
		overflow: hidden;
		width: 100%;
		margin-top: -3px;
		background-color: $white;
		border-color: $dinova-green;
		border-radius: 0 0 5px 5px;
		border-style: solid;
		border-width: 1px 3px 3px;

		&.closed {
			z-index: -2;
			height: 0px !important;
			border-width: 0;
		}

		.dropdown-list {
			z-index: 200;
		}
	}
}
