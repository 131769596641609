.view-reward-options {
	.list-title {
		margin: 0;
	}

	.list {
		padding: 0;
		margin: 0;
		list-style-type: none;

		li {
			display: flex;
			align-items: center;
			margin-top: 50px;

			.points,
			.money {
				font: 24px/28px 'Lato', sans-serif;
			}

			.points {
				margin-right: 5px;
				font-weight: bold;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.list-title {
			text-align: center !important;
		}

		.list li {
			justify-content: center;
			margin-top: 40px;

			.points,
			.money {
				font-size: 22px;
				line-height: 26px;
			}
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		.list-title {
			margin-bottom: 40px;
		}

		.list li {
			margin-top: 25px;
		}
	}
}
