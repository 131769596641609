@import './input-mods.scss';

.search-page.content-wrapper {
	max-width: 100%;
	margin: 0 auto;

	.header {
		position: relative;
		display: flex;

		.back-link {
			position: absolute;
			left: 10px;
			font-weight: 400;
		}

		.title {
			width: 100%;
		}
	}

	.main-content-wrapper {
		.main-content {
			display: flex;
			align-items: flex-start;

			.list {
				overflow: hidden scroll;
				height: 100%;
				max-height: 100%;
			}

			.list,
			.map {
				transition: width ease .3s, transform ease .3s;
			}

			.no-results {
				width: calc(100vw - 20px);
				margin: 0 10px;
			}
		}
	}
}

// mobile only
@media screen and (max-width: 767px) {
	.search-page.content-wrapper {
		overflow: hidden;
		width: 100vw;

		.main-content {
			width: 200vw;
			transform: translateX(-100vw);
			transition: transform ease .3s;

			.list,
			.map {
				width: 100vw;
			}

			&.show-list {
				transform: translateX(0);
			}
		}
	}
}

// tablet and up
@media screen and (min-width: 768px) {
	.search-page.content-wrapper {
		max-width: 1440px;

		.main-content-wrapper {
			margin: 0 10px;

			.main-content {
				height: 90vh;
				max-height: 90vh;

				.list,
				.map {
					// transition: width ease .3s, transform ease .3s;
				}

				.list {
					width: 0%;
				}

				.map {
					width: 60%;
				}

				.business-search-results {
					padding-right: 0px;
					margin-right: 0px;
				}

				&.show-list {
					.list {
						width: 373px;
						min-width: 373px;
					}

					.map {
						width: 50vw;
					}

					.no-results {
						width: calc(100vw - 10px);
						min-width: 373px;
						max-width: 373px;
						margin: 0 10px 0 0;
					}

					.business-search-results {
						padding-right: 5px;
						margin-right: 5px;
					}
				}

				&:not(.show-list) {
					.no-results {
						max-width: 0;
						padding: 0;
						margin: 0;
						border-width: 0;
					}
				}
			}
		}
	}
}

// Desktop and up
@media screen and (min-width: 1024px) {
	.search-page.content-wrapper {
		.main-content-wrapper {
			.main-content {
				.list {
					width: 0%;
					padding-right: 0px;
					margin-right: 0px;
				}

				&.show-list {
					.list {
						width: 484px;
						min-width: 484px;
						padding-right: 5px;
						margin-right: 5px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1440px) {
	.search-page.content-wrapper {
		.main-content-wrapper {
			margin: 0px;
		}
	}
}
