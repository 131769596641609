.social-wrapper {
	flex-wrap: wrap;

	.social-icon {
		height: 55px;
		margin-right: 2em;
		margin-bottom: 1em;
	}
}

@media screen and (min-width: 768px) {
	.social-wrapper {
		.social-icon {
			width: 40px;
		}
	}
}
