@import '../../vars/colors';

.accordionHeader {
	margin-bottom: 10px;
	font-size: 18px;
	font-weight: 500;
}

.accordion {
	border-bottom: 1px solid $grey;

	h5 {
		font-size: 18px;
		font-weight: 300;
		text-transform: unset;
	}

	.card {
		border-top: 1px solid $grey;
		border-right: 1px solid $grey;
		border-left: 1px solid $grey;
		box-shadow: none;

		&.accordion_even {
			background-color: $light-grey;
		}

		&.accordion_odd {
			background-color: $medium-grey;
		}

		.card-header {
			padding: 15px 20px;
			border-bottom: 0;
			background: transparent;

			&.Active {
				h5 {
					font-weight: 500;
				}
			}

			a {
				color: $black-text;
				text-decoration: none;
			}

			.angle-down {
				width: 20px;
				height: 20px;
				margin-top: 5px;
				margin-right: 0;
				margin-left: 5px;
				color: $black-text;
				float: right;

				.rotate-icon {
					transform: rotate(180deg);
				}
			}
		}

		.card-body {
			padding: .25rem 1.5rem;
			background-color: transparent;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.5;
		}

		.card-block {
			padding-top: .1rem;
		}
	}
}

.accordion.highlight {
	.accordion__item {
		.accordion__title {
			background-color: $pale-dinova-green;

			h3.section-title {
				color: $dinova-green;
			}
		}
	}
}
