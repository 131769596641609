.active-filter-bar {
	display: flex;
	min-height: 30px;
	align-items: baseline;
	justify-content: flex-start;

	&.active {
		padding: 5px 10px;
	}

	.text {
		margin-right: 5px;
		margin-bottom: 0px;
	}

	.active-filter-list-wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.active-filter-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 5px;
		color: $required-red;
		cursor: pointer;
		font-weight: 600;

		&.unavailable {
			color: $grey-text;
		}

		img, .grey-x {
			width: 20px;
			height: 20px;
			margin-left: 2px;
		}

		.grey-x {
			position: relative;
			z-index: 2;
			border: 2px solid $grey-text;
			border-radius: 50%;
			cursor: pointer;

			.before,
			.after {
				position: absolute;
				z-index: 0;
				top: 7px;
				width: 12px;
				height: 2px;
				background-color: $grey-text;
				border-radius: 0;
				content: '';
			}

			.before {
				left: 2px;
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}

			.after {
				right: 2px;
				-webkit-transform: rotate(-135deg);
				-moz-transform: rotate(-135deg);
				transform: rotate(-135deg);
			}
		}
	}
}
