.radio-option-wrapper {
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 0px;
	}
}

.inline {
	.radio-option-wrapper {
		margin-right: 10px;
		margin-bottom: 0px;
	}
}

input.radio-option {
	display: none;
}

label.radio.sub {
	transition: all .28s ease;

	&:before {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: 23px;
		height: 23px;
		// margin: auto;
		background: linear-gradient(45deg, #fff 0%, #eaeaea 100%);
		border-color: #aaa;
		border-radius: 50%;
		border-style: solid;
		border-width: 1px;
		content: '';
		transition: all .28s ease;
	}

	&:after {
		position: absolute;
		z-index: 0;
		z-index: -1;
		top: 0;
		left: 0;
		width: 15px;
		height: 15px;
		margin: 4px;
		border-color: #aaa;
		border-radius: 50%;
		border-style: solid;
		border-width: 2px;
		content: '';
		transition: all .28s ease;
	}
}

input.radio-option:checked {
	+ label:after {
		z-index: 1;
		background: green;
		border-color: green;
	}
}
