.service-notice.catering {
	z-index: 1;

	.horizontal-nav {
		margin: 0 auto;

		.nav-item {
			margin-bottom: 10px;
		}
	}

	// mobile only
	@media screen and (max-width: 767px) {
		padding: 1em 1em 2em;
		border-bottom: solid $black 1px;
		margin-bottom: 2em;
	}

	// tablet and up
	@media screen and (min-width: 768px) {
		width: 65%;
		padding: 0 1.5em;
		margin: 2em 0;
		border-color: $input-border-grey;
		border-style: solid;
		border-width: 0 2px 0 0;

		.horizontal-nav {
			max-width: 78%;
		}
	}
}
