.details-location {
	.map-wrapper {
		width: 100%;
		height: 400px;

		div {
			cursor: default !important;
		}
	}

	.map-info {
		margin: .5em 0;

		.addresses,
		.distance,
		.direction-btn {
			@include fluid-type-default(13px, 16px);
		}

		.distance {
			font-weight: 400;
		}

		.right-column {
			height: 100%;
		}

		.direction-btn {
			padding: 8px 15px;
			margin-top: 5px;
			background-color: $orange-peel;
			color: $black-text;
			font-weight: 400;
			text-decoration: none;
			text-transform: uppercase;
		}
	}

	// Hide Google Map Els
	.gmnoprint,
	.gm-style-cc,
	[href*='https://maps.google.com/maps'] {
		display: none !important;
	}
}

@media screen and (min-width: 768px) {
	.details-location {
		.map-wrapper {
			height: 300px;
		}
	}
}
