.section-header-section {
	display: flex;
	flex-direction: column;
	padding: 20px 0;

	.links {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	// Back button
	a.back-link {
		width: 50px;
		font-weight: 400;

		@include fluid-type-default(14px, 20px);
	}

	.title {
		pointer-events: none;
		text-align: center;

		.text.section-title {
			display: inline-block;
			margin-top: 1em;
			font-weight: 400;

			@include fluid-type-default(14px, 18px);
		}
	}
}

@media screen and (min-width: 768px) {
	.section-header-section {
		flex-direction: column-reverse;

		.links {
			margin: -50px 20px .75em;
		}
	}
}

// only on mobile screens
@media screen and (max-width: 767px) {
	.section-header-section {
		.links {
			min-height: 40px;
		}
	}
}
