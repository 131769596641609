.link {
	display: flex;
	justify-content: center;
	color: $dinova-green;

	p.text {
		margin: 0;
		color: $dinova-green;
		cursor: pointer;
		font-weight: 600;
		text-decoration: underline;
	}

	&.no-click,
	&.no-click p.text {
		cursor: default;
		text-decoration: none;
	}
}
