// TODO a lot of !important. need to rewrite it
.check-btn {
	// small green checkbox
	&.small {
		[type='checkbox'] {
			+ label {
				padding-left: 30px !important;
				margin-right: 0 !important;

				&:before {
					top: 4px !important;
					left: 2px !important;
					width: 6px !important;
					height: 12px !important;
				}

				&:after {
					top: 4px !important;
					width: 18px !important;
					height: 18px !important;
					border: 2px solid $dinova-green !important;
					border-radius: 0 !important;
				}
			}

			&:checked + label {
				&:before {
					border-color: transparent $dinova-green $dinova-green transparent !important;
				}

				&:after {
					background-color: $white !important;
				}
			}
		}
	}

	// Inverted Checkbox
	&.inversion {
		// Checked
		[type='checkbox']:checked {
			// Label
			+ label {
				&:before {
					border-color: transparent $dinova-green $dinova-green transparent !important;
				}

				&:after {
					background-color: $white !important;
					border-color: $checkbox-border-grey !important;
				}
			}
		}
	}
}
