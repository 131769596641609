.dashboard-connect-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	border: solid 1px $grey;
	background-color: $white;

	.title.text-normal {
		@include fluid-type-default(14px, 16px);
	}

	.text.d-block {
		padding-top: 5px;
		font-weight: 300;
		line-height: 1.4;

		@include fluid-type-default(12px, 16px);
	}

	a.btn.btn-primary {
		margin-bottom: 0px;

		@include fluid-type-default(14px, 16px);
	}
}
