// details page
.recommendations {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1em 0;
	margin: 0 -10px 1em;
	background-color: $pale-dinova-green;

	.endorse {
		p.text {
			margin-left: 0px;
		}
	}
}

// history page
@media screen and (min-width: 768px) {
	.recommendations.transactions {
		flex-direction: row;
		justify-content: center;

		.incentive {
			p.text {
				margin: 0px 0px 0px 1em;
			}
		}
	}
}

.transactions_list {
	.recommendations {
		margin: 0 -15px;

		&.transaction-didSubmit {
			.text {
				margin: 0px;
			}
		}
	}
}
