.details-recommendations.options-list {
	display: flex;
	flex-wrap: wrap;

	.option-item {
		width: unset;
		flex: 1 0 calc(50%);
		padding-right: .5em;
		white-space: nowrap;
	}
}
