.with-help {
	position: relative;

	.children {
		width: calc(100% - 50px); // 50px = help button with.
		min-height: 10px; // Help button height.
	}

	.title {
		margin-top: 1px;
		margin-right: 51px;
		margin-bottom: 0px;
		cursor: pointer;
	}

	& > .title {
		color: $dinova-green;
	}

	.help-btn,
	.trigger {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	// <<<<<<< HEAD
	.help-panel-wrapper,
	.trigger-panel-wrapper {
		margin-top: 5px;
		// =======
		// .help-panel-wrapper {
		// 	position: absolute;
		// 	z-index: 2000;
		// 	right: 0px;
		// 	margin-top: 10px;
		//
		// 	&.big-pad {
		// 		margin-top: 17px;
		// 	}
		// >>>>>>> master
	}
}

.with-help.floating {
	position: relative;

	.help-btn {
		position: static;
	}

	.help-panel-wrapper {
		position: absolute;
		z-index: 999;
		top: 35px;
		right: 0px;
		width: 400px;
		max-width: 70vw;
	}
}
