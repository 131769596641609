.page-container > .page-header {
	padding: 0;

	> .row {
		margin: 0;

		> .col {
			padding: 0;
		}
	}

	.page-header-col {
		height: unset !important;
	}

	.page-banner {
		width: 100%;
		height: 275px;
		object-fit: cover;
		object-position: center center;
	}

	.header-bar {
		display: flex;
		align-items: center;
		padding: 25px 0;
		border: solid red 3px;

		> *:not(:last-child) {
			margin-right: 10px;
		}

		// for table and up
		@include media-breakpoint-up(md) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		// for desktop
		@include media-breakpoint-up(lg) {
			padding-right: 20px;
			padding-left: 20px;
		}
	}
}
