.recommendation-count {
	display: flex;
	width: 100%;
	justify-content: center;
}

@media screen and (min-width: 768px) {
	.recommendation-count {
		width: auto;
	}
}
