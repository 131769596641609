.horizontal-nav {
	display: flex;
	max-width: 100%;
	flex-wrap: wrap;
	justify-content: center;

	.nav-item {
		padding: 0px 15px;
		border-color: $black;
		border-style: solid;
		border-width: 0 1px 0 0;
		cursor: pointer;
		text-decoration: none;

		&:last-child {
			border-width: 0;
		}

		.text {
			margin: 0;
			color: $dinova-green;
			white-space: nowrap;
		}
	}
}
