.connect-to-points {
	.company-selection {
		display: flex;
		height: 60px;
		flex-wrap: wrap;
		align-items: center;
		padding: 0;
		margin-top: 15px;
		font-size: 18px;

		> * {
			white-space: nowrap;

			&:not(:last-child) {
				margin-right: 5px;
			}
		}

		.not-your-company {
			color: $dinova-green;
			cursor: pointer;
			text-decoration: underline;
		}
	}

	.with-help {
		.help-panel-wrapper,
		.help-panel-wrapper.big-pad {
			top: 100%;
			margin-top: 0px;
		}
	}
	
	.column1,
	.column2 {
		.col {
			padding: 0;
		}
	}

	.email-address-container {
		width: calc(100% - 60px);
		margin: 5px 0 0 10px;

		.email-field {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 15px;
			font-size: 18px;

			> * {
				white-space: nowrap;

				&:not(:last-child) {
					margin-right: 5px;
				}
			}
		}

		.example-label {
			color: #4d4c48;
			font: 16px/20px 'Lato', sans-serif;
			font-weight: 300;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.company-selection.margin-bottom {
			margin-bottom: 41px;
		}
	}
}
