.locations-bar {
	display: flex;
	flex-direction: column;
	align-items: baseline;

	h3 {
		width: 100%;
		text-transform: none;

		@include fluid-type-default(16px, 30px);
	}

	.drop-down {
		.drop-down-list {
			width: 97vw;
			max-width: 600px;
		}

		> p {
			@include fluid-type-default(14px, 20px);
		}
	}
}

@media screen and (min-width: 1024px) {
	.locations-bar {
		flex-direction: row;

		h3 {
			width: auto;
		}

		.drop-down {
			margin-left: 1em;
		}
	}
}
