// Default Checkbox
.form-check-input[type='checkbox'] {
	// Label - Not Checked
	+ label {
		padding-left: 30px;
		font-size: 1.125em;
		line-height: 2.57rem;
	}

	// Checked
	&.filled-in,
	&.filled-in:checked {
		// Label
		+ label:before {
			top: 0;
			left: 2px;
			width: 9px;
			height: 18px;
			border-color: transparent $white $white transparent;
		}

		+ label:after {
			width: 26px;
			height: 26px;
			background-color: $dinova-green;
			border-color: $dinova-green;
			border-radius: 5px;
			border-width: 1px;
		}
	}

	// Error
	&.error:not(:checked) {
		// Label
		+ label:after {
			border-color: $danger-red;
			border-width: 3px;
		}
	}
}

// Mobile
@media screen and (max-width: 400px) {
	// Checkbox label
	.form-check-input[type='checkbox'] + label {
		font-size: 1.7rem;
	}
}

// Desktop
@media screen and (min-width: 768px) {
	// Checkbox label
	.form-check-input[type='checkbox'] + label {
		padding-left: 43px;
	}
}
