.step {
	.registration-step-header {
		text-align: left;
	}

	padding-bottom: 15px;

	@media (min-width: 768px) {
		padding-right: 0;
		padding-bottom: 30px;
		padding-left: 0;
	}

	@media (min-width: 1024px) {
		.registration-step-header {
			text-align: center;
		}
	}
}
