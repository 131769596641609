.Di_CookieContent {
	position: relative;
	width: 95%;
}

.Di_CookieContent p {
	color: $white;
}

.Di_CookieBttn {
	position: absolute;
	top: 10px;
	right: 10px;
	border: 2px solid $black !important;
	background-color: $black !important;
	color: $white;
}

.Di_CookieContainer {
	position: fixed;
	z-index: 1000;
	top: auto;
	bottom: 0;
	width: 100%;
	height: auto;
	min-height: 10px;
	padding: 25px;
	background-color: $black;
	color: $white;
	text-align: center;
}

@media (max-width: 767px) {
	.Di_CookieContainer {
		top: auto;
		bottom: 0;
		height: auto;
		min-height: 10px;
	}
}

@media (min-width: 768px) {
	.Di_CookieContainer {
		top: auto;
		bottom: 0;
		height: auto;
		min-height: 10px;
	}
}

@media (min-width: 1024px) {
	.Di_CookieContainer {
		top: 0;
		bottom: auto;
		height: auto;
		min-height: 10px;
	}
}
