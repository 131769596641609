.details-about {
	.section-body {
		padding: .75em;
	}

	// Both columns
	.row > .col-md-6 {
		padding-left: 0;
	}

	// Second column
	.row > .col-md-6:nth-child(2) {
		padding-right: 0;
		padding-left: 15px;
	}

	.details-location {
		.section-body {
			padding: 0;
		}
	}

	// Details section
	.row .col-md-6:first-child .accordion:first-child .section-body {
		padding-left: 0px;
	}

	.options-list {
		justify-content: space-between;

		.option-item {
			width: 48%;
			flex: unset;

			@include fluid-type-default(13px, 16px);

			&:before {
				width: 20px;
				height: 20px;
			}
		}
	}

	.keywords-section {
		margin-top: 3em;
	}
}

@media screen and (min-width: 768px) {
	.details-about {
		.accordion {
			border: none;
			margin-top: 20px;
			margin-bottom: 20px;

			.accordion__item {
				.accordion__title {
					border: none;
				}

				.section-body {
					padding: 0 0 0 3vw;
				}
			}
		}

		.accordion:not(.highlight) {
			.accordion__item {
				.accordion__title {
					background-color: transparent;
				}
			}
		}

		.accordion.highlight {
			.section-body {
				margin-top: .5em;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.details-about {
		.accordion {
			.accordion__item {
				.section-body {
					padding: 0 0 0 2vw;
				}
			}
		}
	}
}
