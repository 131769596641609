.page-container {
	padding: 0;

	> .row {
		margin: 0;

		> .col {
			padding: 0;
		}
	}

	.page-banner {
		width: 100%;
		height: 275px;
		object-fit: cover;
		object-position: center center;
	}

	.header-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 25px 0;
		margin: 0 auto;

		.col-2.help {
			.with-help {
				text-align: right;

				.help-panel-wrapper {
					text-align: left;
				}
			}
		}

		// for table and up
		@include media-breakpoint-up(md) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		// for desktop
		@include media-breakpoint-up(lg) {
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	.body {
		margin: 0 auto;
	}
}
