.edit-payment-form {
	.required-field-note {
		margin-bottom: 30px;
	}

	.profile-section-list {
		padding: 0 15px;

		.profile-section-item {
			.title {
				color: #000;
				font-weight: 400;
				text-transform: none !important;
			}

			.item-value {
				color: #000;
				font-weight: bold;
			}
		}
	}

	.add-another-card {
		color: $dinova-green !important;
		font-weight: bold;
		line-height: 1.2;

		@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 16px, 20px);
	}

	.field {
		margin-top: 0;

		&:not(:first-child) {
			margin-top: 30px;
		}
	}

	.delete-payment-wrapper {
		margin-top: 40px;
		text-align: center;

		.delete-payment-btn {
			color: $dinova-green !important;
			cursor: pointer;
			font-size: 16px;
			line-height: 20px;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.required-field-note {
			margin-bottom: 24px;
		}

		.add-another-card {
			@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 18px, 22px);
		}

		.field:not(:first-child) {
			margin-top: 28px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.required-field-note {
			margin-bottom: 25px;
		}

		.add-another-card {
			@include fluid-type(map-get($grid-breakpoints, xs), map-get($grid-breakpoints, md), 16px, 20px);
		}

		.field:not(:first-child) {
			margin-top: 25px;
		}
	}
}
