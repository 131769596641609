.registration-page {
	.content-inner {
		max-width: 1070px;
		padding: 0 25px;
		margin: 0 auto;
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.Di_RegisterPic {
			background-position-y: -168px;
		}
	}
}
