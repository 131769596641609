.restaurant-wrapper {
	.other-restaurant-links-wrapper {
		transition: height ease .3s;

		&.closed {
			overflow: hidden;
			height: 0px !important;
		}
	}

	.another-restaurant-link {
		display: block;
		background-color: #dcdcdc;
		text-decoration: none;

		&:nth-child(even) {
			background-color: #ececec;
		}

		.another-restaurant-item {
			position: relative;
			display: flex;
			align-items: center;
			padding: 10px 12px;
			color: $deeper-grey;

			.restaurant-name {
				overflow: hidden;
				flex: 1 1 0;
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				text-overflow: ellipsis;
				text-transform: none;
				white-space: nowrap;
			}

			.distance {
				margin: 0 28px 0 10px;
				font-weight: 400;

				@include fluid-type-default(12px, 16px);
			}

			.restaurant-list-marker {
				position: absolute;
				top: 50%;
				right: 10px;
				height: 25px;
				transform: translateY(-50%);
			}

			.locations {
				position: static;
			}

			&.no-marker .distance {
				margin-right: 0;
			}
		}
	}

	.restaurant-link {
		display: block;
		text-decoration: none;

		.restaurant-item {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: space-between;
			padding: 0;
			margin-bottom: 0;
			color: #fff;

			.restaurant-img {
				width: 100%;
				height: 177px;
				object-fit: cover;
			}

			.footer {
				display: flex;
				width: 100%;
				justify-content: space-between;
				padding: 13px 15px;

				.info {
					overflow: hidden;

					.restaurant-name {
						overflow: hidden;
						margin: 0;
						color: #1c871c;
						font-weight: bold;
						text-overflow: ellipsis;
						text-transform: none;

						@include fluid-type-default(14px, 20px);
					}

					.d-flex {
						align-items: baseline;

						.cuisine-name {
							padding-right: 3.2vw;
							margin: 8px 0 0;
							color: $deeper-grey;
							font-weight: 400;
							text-transform: none;

							@include fluid-type-default(14px, 18px);
						}
					}
				}

				.additional-info {
					display: flex;
					min-width: 100px;
					flex: 1 0 auto;
					flex-direction: column;
					align-items: flex-end;
					align-self: stretch;
					justify-content: space-between;
					margin-left: 10px;

					&.no-marker {
						align-self: auto;
					}

					.distance {
						color: $deeper-grey;
						font-weight: bold;
						line-height: 1;

						@include fluid-type-default(14px, 20px);
					}

					.locations {
						height: 16px;
						margin-top: 8px;
						color: $deeper-grey;
						cursor: pointer;
						font-weight: 400;
						line-height: 16px;

						@include fluid-type-default(14px, 18px);

						.locations-inner {
							display: flex;
							align-items: center;
							justify-content: flex-end;

							.locations-number {
								white-space: nowrap;
							}

							.caret {
								display: inline-block;
								width: 12px;
								margin-left: 10px;
								font-size: .029vh;
								transform: rotate(180deg);

								.img-fluid {
									height: 14px;
								}
							}
						}

						&.open .caret {
							transform: rotate(0);
						}
					}
				}
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.another-restaurant-link {
			.another-restaurant-item {
				padding: 5px 10px;

				.restaurant-name {
					font-size: 12px;
				}

				.distance {
					font-size: 12px;
				}
			}
		}

		.restaurant-link .restaurant-item {
			.restaurant-marker-wrapper {
				position: absolute;
				top: 10px;
				right: 10px;
				flex: 1 1 0;

				.restaurant-list-marker {
					width: 28px;
					height: 40px;
					object-fit: contain;
					object-position: top;

					&.active {
						transform: scale(1.1, 1.1);
					}
				}
			}

			.restaurant-img {
				height: 160px;
			}

			.footer {
				padding: 10px;

				.info {
					.restaurant-name {
						font-size: 14px;
					}

					.d-flex .cuisine-name {
						font-size: 12px;
					}
				}

				.additional-info {
					.distance {
						font-size: 14px;
					}

					.locations {
						font-size: 12px;
					}
				}
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.another-restaurant-link {
			.another-restaurant-item {
				padding: 10px 12px;

				.restaurant-name {
					font-size: 14px;
				}

				.distance {
					margin-right: 35px;
					font-size: 14px;
				}

				.restaurant-list-marker {
					right: 12px;
					height: auto;
				}
			}
		}

		.restaurant-link .restaurant-item {
			.restaurant-img {
				// width: 460px;
				height: 200px;
			}

			.footer {
				padding: 10px 10px 10px 0;

				.info {
					.restaurant-name {
						font-size: 16px;
					}

					.d-flex .cuisine-name {
						font-size: 14px;
					}
				}

				.additional-info {
					.distance {
						font-size: 16px;
					}

					.locations {
						font-size: 14px;
					}
				}
			}
		}
	}
}
