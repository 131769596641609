// Don't allow page content to scroll if modal is open.
body.basic-modal-open {
	overflow: hidden !important;
}

.basic-modal {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	// padding-bottom: 50px;
	background-color: rgba(0, 0, 0, .8);

	.basic-modal-content-wrapper {
		width: 90%;
		max-width: 1000px;
		max-height: 95%;
		padding: 1em 2.5em;
		margin: 2.5% auto;
		// margin: 120px auto 0;
		background-color: $white;
		border-radius: 4px;

		.basic-modal-content {
			max-height: calc(95vh - 2em);
		}

		.close-wrapper {
			text-align: right;

			.close-btn {
				width: 15px;
				height: 15px;
				margin: 6px 11px 0 0;
				cursor: pointer;
			}
		}
	}
}

.basic-modal.center {
	display: flex;
	align-items: flex-start;
	justify-content: center;

	.basic-modal-content-wrapper {
		margin: 20vh auto auto;
	}
}
