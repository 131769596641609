.edge {
	@include media-breakpoint-up(lg) {
		.search-tab.catering-search-tab {
			.search-group {
				[class*='input-wrapper']:nth-child(2) input {
					padding: 13px 15px;
				}

				.search-btn-wrapper {
					.search-btn {
						padding: 9px 0;
					}
				}
			}
		}
	}
}
