.restaurant-cta-menu {
	display: inline-flex;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 1em;

	.cta-btn {
		flex: 1;
		padding: 8px;
		border: solid orange 2px;
		margin: 0 1.5vw;
		color: $black-text;
		font-weight: 600;
		text-decoration: none;

		@include fluid-type-default(12px, 16px);

		img {
			height: 20px;
			padding: 0 .5em;
			margin-top: -3px;
		}
	}
}

@media screen and (min-width: 768px) {
	.restaurant-cta-menu {
		justify-content: center;

		.cta-btn {
			flex: unset;
			flex-direction: row;
		}
	}
}

@media screen and (min-width: 1024px) {
	.restaurant-cta-menu {
		.cta-btn {
			margin: 0 .25vw .75em;
		}
	}
}
