.completion {
	.inner-content {
		display: flex;
		max-width: 564px;
		flex-direction: column;
		margin: 0 auto;

		.title {
			color: #17871b;
			font-size: 24px;
			font-weight: bold;
			text-transform: uppercase;
		}

		.desc,
		.download-text {
			margin: 0;
			color: #4d4c48;
			font-size: 18px;
			font-weight: 300;
			text-align: center;
		}

		.continue-btn {
			align-self: center;
			margin: 30px 0;
		}

		.stores {
			display: flex;
			justify-content: space-between;

			.appleBadge,
			.playBadge {
				display: block;
				overflow: hidden;
				width: 150px;
				height: 45px;
				margin-right: auto !important;
				margin-left: auto !important;
			}

			.appleBadge {
				background: url(#{$cdn-basePath}/mydinova/logos/apple-app-store-badge.svg) no-repeat;
				background-size: contain;
			}

			.playBadge {
				background: url(#{$cdn-basePath}/mydinova/logos/google-play-badge.svg) no-repeat;
				background-size: contain;
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.inner-content .stores {
			.appleBadge,
			.playBadge {
				width: 240px !important;
				height: 75px;
			}
		}
	}
}
