.refer {
	.header-bar {
		min-width: 280px;
		padding-right: 0px;
		padding-right: 20px;
		padding-left: 0px;
		padding-left: 20px;

		.col-2 {
			max-width: 12%;
			flex: 0 0 12%;
		}

		.col-7 {
			max-width: 76%;
			flex: 0 0 76%;
		}

		.orange-text {
			margin: 0;
		}
	}

	.refer-body-content {
		@include fluid-type-default(16px, 18px, true);
	}

	&.page-container > .page-header {
		.page-banner {
			max-width: 100vw;
			height: unset;
			max-height: 275px;
		}
	}

	button {
		cursor: pointer !important;
	}

	.sections {
		min-width: 280px;
		padding-right: 20px;
		padding-left: 20px;

		.desc {
			padding: 0 25px 50px;
			margin: 0;
		}

		> .section {
			padding: 50px 25px;
			border-top: solid 2px $foggy-grey;
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.sections {
			.desc {
				padding: 0 36px 40px;
			}

			> .section {
				padding: 40px 36px;
			}
		}
	}

	// for desktop
	@include media-breakpoint-up(lg) {
		.header-bar {
			max-width: 920px !important;
			margin: 0 auto;
		}

		.sections {
			max-width: 920px;
			margin: 0 auto;

			.desc {
				padding: 0 0 40px;
			}

			> .section {
				padding: 40px 0;
			}
		}
	}
}
