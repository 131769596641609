.dropdown {
	.label-wrapper {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		padding: 9px 12px 10px;
		border: solid $input-border-grey 1px;
		border-radius: 5px;

		.label {
			@include fluid-type-default(14px, 18px);

			margin: 0;
			color: $black;
			transition: .3s ease;
		}

		.value {
			position: absolute;
			margin: 0;
			font-weight: 400;

			@include fluid-type-default(14px, 18px);
		}

		.label,
		.value {
			white-space: nowrap;
		}
	}

	&.has-value {
		.label-wrapper {
			padding: 10px 12px;
			border-top-width: 0px !important;

			.label {
				transform: translateY(-100%);

				@include fluid-type-default(12px, 16px);
			}
		}
	}

	&.closed {
		.label-wrapper {
			transition: all .3s ease 0s;
		}
	}

	&.open {
		.label-wrapper {
			padding: 7px 10px 8px;
			border-color: $dinova-green;
			border-radius: 5px 5px 0 0;
			border-width: 3px;
			transition: all .3s ease 0s;

			&.error {
				padding: 10px 10px 7px;
			}
		}

		&.has-value {
			.label-wrapper {
				padding: 10px 10px 8px;
			}
		}
	}
}
