.map-marker {
	position: relative;
	display: inline;

	img,
	.map-marker-label {
		position: absolute;
	}

	.map-marker-label {
		top: -10px;
		left: 6px;
		min-width: 150px;
		color: $fire-orange;
		font-weight: 600;

		@include fluid-type-default(13px, 16px);
	}

	img {
		right: -15px;
		bottom: 100%;
		width: 30px;
	}
}
