.cluster-map-marker {
	position: relative;
	width: 28px;
	height: 40px;
	cursor: pointer;

	.marker {
		position: absolute;
		z-index: 1;
		height: inherit;
	}

	.cluster-amount {
		position: absolute;
		z-index: 2;
		right: 0;
		left: 0;
		margin-top: 8px;
		color: white;
		text-align: center;
	}
}
