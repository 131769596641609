.profile-page {
	.page-header {
		.row {
			.col {
				display: flex;
				overflow: hidden;
				height: 125px;

				.page-banner {
					min-width: 100%;
					height: unset;
				}
			}
		}
	}

	.header-bar {
		justify-content: space-between;
		padding: 1.25em 2.5vw;

		.col-2,
		.col-7 {
			display: flex;
			width: calc(100% / 3);
			max-width: unset;
			flex: unset;
			padding-right: 0;
			padding-left: 0;

			.page-header-links {
				.link-back {
					font-size: 14px;
					font-weight: bold;
					text-decoration: none;
				}
			}
		}

		:first-child {
			justify-content: flex-start;
		}

		:nth-child(2) {
			justify-content: center;
		}

		:nth-child(3) {
			justify-content: flex-end;
		}
	}

	.container:nth-child(2) {
		padding: 0;
	}

	.profile-sections {
		.desc {
			padding: 1.25em 2.5vw;
			border-bottom: solid rgba(0, 0, 0, .125) 1px;
			margin-bottom: 0;
			font-size: 14px;
		}
	}
}

@media (min-width: 576px) {
	.profile-page .container {
		max-width: unset;
	}
}

@media (min-width: 768px) {
	.profile-page .container {
		max-width: 100vw;

		&.page-header {
			.row {
				.col {
					height: 200px;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.profile-page {
		.header-bar {
			.col-2,
			.col-7 {
				.page-header-links {
					.link-back {
						font-size: 16px;
					}
				}
			}
		}

		.profile-sections {
			.desc {
				font-size: 16px;
			}
		}
	}
}

@media (min-width: 1200px) {
	.profile-page .container {
		max-width: 100vw;
	}
}

@media (min-width: 1440px) {
	.profile-page .container {
		max-width: 1440px;
	}
}
