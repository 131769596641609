.recommendations_modal {
	.basic-modal-content-wrapper {
		max-width: 550px !important;

		.title {
			max-width: 90%;
			flex: 1 0 75px;
			margin: auto auto 20px;
		}
	}

	.text.pos-sub-title {
		margin: 1em 0 !important;
	}

	.rec-type-toggles {
		padding: 1em;
		margin: 0 -2.5em;
		background-color: $pale-dinova-green;

		.row.center {
			width: 100%;
			justify-content: center;
		}

		.btn {
			width: 95%;
			padding: 8px 14px;
			margin: 0 2.5%;
		}
	}

	form {
		max-height: calc(95vh - 90px - 2em);

		[class*='col'] {
			.title {
				// display: inline-block;
				width: 100%;
				max-width: 100%;
				padding: 1rem;
				border: solid $grey 1px;
				border-bottom: none;
				margin: 0;
				border-radius: 4px 4px 0 0;

				+ div {
					padding: 1rem;
					border: solid $grey 1px;
					// border-top: none;
					margin-bottom: .5rem;
					border-radius: 0 0 4px 4px;
				}
			}

			&:not(:first-child) {
				.title {
					margin-top: .5rem;
				}
			}
		}

		.custom-scroll {
			padding: 0 8px;
			margin: 0 -18px;

			& > [class*='col'] {
				margin-bottom: 1.75rem;
			}
		}

		> div:nth-child(1) {
			overflow: hidden auto;
			max-height: calc(88vh - 191px - 2em - 90px);
		}

		> .scroll-modal-buttons {
			height: 191px;
			padding: 1em 20px;
			margin-right: -40px;
			margin-bottom: -1em;
			margin-left: -40px;
			box-shadow: 0 2px 4px 2px rgba(0, 0, 0, .23);
		}
	}

	form .row:first-of-type {
		align-items: flex-start;

		textarea {
			height: 120px;
		}

		.input-container.text {
			margin-bottom: 15px;
		}
	}

	.input-wrapper,
	.input-wrapper * {
		text-align: left !important;
	}

	.input-wrapper {
		padding-left: 0px !important;
	}

	.input-wrapper:first-of-type {
		margin-top: 0px;
	}

	.input-wrapper.col-md-12:last-of-type /* textarea */ {
		margin-top: 10px;
		margin-bottom: 0px;
	}

	.submit.btn {
		width: 100%;
		margin-top: 0px;
	}

	button {
		margin-right: 0px;
		margin-left: 0px;
	}

	@media screen and (max-width: 768px) {
		.basic-modal-content-wrapper {
			// margin-top: 20px !important;
		}
	}

	@media screen and (min-width: 1024px) {
		.input-wrapper {
			margin-bottom: 15px;
		}
	}
}

.recommendations_modal.prefill {
	&.positive {
		.row.custom-scroll {
			overflow: hidden auto;
			max-height: calc(88vh - 191px - 2em - 90px - 100px); // account for buttons, title, subtitle, padding.
		}
	}

	&.negative {
		.row.custom-scroll {
			overflow: hidden auto;
			max-height: calc(88vh - 191px - 2em - 90px - 158px);
			// account for buttons, title, subtitle, padding, footer disclaimer
		}
	}
}

.recommendations_modal.none-selected {
	&.positive {
		.row.custom-scroll {
			overflow: hidden auto;
			max-height: calc(88vh - 191px - 2em - 90px - 80px); // account for buttons, title, subtitle, padding.
		}
	}

	&.negative {
		.row.custom-scroll {
			overflow: hidden auto;
			max-height: calc(88vh - 191px - 2em - 90px - 158px);
			// account for buttons, title, subtitle, padding, footer disclaimer
		}
	}
}


.recommendations_modal.prefill.none-selected {
	&.positive {
		.row.custom-scroll {
			overflow: hidden auto;
			max-height: calc(88vh - 191px - 2em - 90px - 80px - 63px); // account for buttons, title, subtitle, padding.
		}
	}

	&.negative {
		.row.custom-scroll {
			overflow: hidden auto;
			max-height: calc(88vh - 191px - 2em - 90px - 193px);
			// account for buttons, title, subtitle, padding, footer disclaimer
		}
	}
}
