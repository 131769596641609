.accordion {
	border-bottom-width: 0px !important;

	.accordion__item {
		overflow: hidden;

		.accordion__title {
			position: relative;
			background-color: $accordion-title-grey;
			border-color: rgba(204, 204, 204, 1);
			border-style: solid;
			border-width: 1px;
			cursor: pointer;
			outline: 0;

			&[aria-selected='false'] {
				border-width: 1px 1px 0px;
			}

			.section-title {
				margin-bottom: 0px;
				font-weight: 600;

				@include fluid-type-default(14px, 20px);

				&.left {
					text-align: left;
				}

				&.right {
					text-align: right;
				}
			}

			.section-title:not([class*='col']) {
				padding: .75em .5em;
			}

			.accordion__arrow {
				top: 50%;
				right: 5px;
				display: none;

				&:before {
					left: 4px;
				}

				&:after {
					right: 4px;
				}
			}
		}

		.section-body {
			&.accordion__body--hidden {
				height: 0;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}

	&:last-of-type {
		.accordion__title {
			&[aria-selected='false'] {
				border-width: 1px;
			}
		}
	}

	&.collapsible {
		.accordion__item {
			.accordion__title {
				.accordion__arrow {
					display: block;
				}
			}
		}
	}

	&.striped:nth-child(odd) {
		.accordion__item {
			.accordion__title {
				background-color: $accordion-title-light-grey;
			}
		}
	}
}
