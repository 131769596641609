.Di_MyDinovaLogo {
	width: 100%;
	margin-top: 20px;
	text-align: center;
}

.Di_LogoBoxWide {
	img {
		height: 50px;
		margin-top: 5px;
		margin-right: auto;
		margin-left: auto;
	}
}

@media (min-width: 768px) {
	.Di_LogoBoxWide {
		img {
			height: 60px;
			margin-top: 5px;
			margin-right: auto;
			margin-left: 25px;
		}
	}
}

@media (min-width: 1024px) {
	.Di_LogoBoxWideTall {
		padding-top: 20px;
	}

	.Di_LogoBoxWide {
		img {
			height: 70px;
			margin-top: 5px;
			margin-right: auto;
			margin-left: 25px;
		}
	}
}
