.place-autocomplete {
	height: 250px;
	margin: 5px 0 10px;
	overflow-y: auto;

	.item {
		display: block;
		width: 100%;
		min-height: 50px;
		padding: 0 20px;
		margin: 0;
		line-height: 22px;
		text-align: left;
		text-transform: none;

		@include fluid-type-default(12px, 18px);

		&:hover {
			background-color: #f6f6f6;
			font-weight: bold;
			text-decoration: none;
		}
	}
}
