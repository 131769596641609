.ff {
	@include media-breakpoint-up(lg) {
		.search-tab.catering-search-tab {
			.search-group {
				[class*='input-wrapper']:nth-child(2) input {
					padding: 14px 15px;
				}

				.search-btn-wrapper {
					.search-btn {
						padding: 10px 0;
					}
				}
			}
		}
	}

	@media screen and (min-width: 1024px) {
		.search-tab.catering-search-tab {
			&.hasError {
				.search-group {
					[class*='input-wrapper']:nth-child(2) input {
						padding: 11px 15px !important;
					}

					.search-btn-wrapper {
						.search-btn {
							padding: 9px 0;
						}
					}
				}
			}
		}
	}
}
