body.modal-open {
	overflow: hidden;
}

.modal.fade {
	.modal-dialog.modal-dialog-centered {
		margin: auto;
	}
}

.modal-layout {
	width: 100%;
	max-width: none;
	margin: 0 auto;

	.modal-content {
		overflow: hidden;
		height: 100vh;
		padding: 0 0 25px;
		border: none;
		background-color: $white;

		.modal-header {
			position: relative;
			justify-content: center;
			padding: 20px;
			border-bottom: none;

			.modal-title {
				width: 100%;
				color: $black;
				font-weight: 400;
				text-align: center;
				text-transform: capitalize;

				@include fluid-type-default(20px, 30px);

				.btn {
					position: absolute;
					z-index: 10;
					top: 40px;
					right: 0;
					width: auto;
					padding: .5rem .75rem;
					margin: 0;
					color: rgb(75, 156, 20) !important;
					cursor: pointer !important;
					font-size: 3vw;
					line-height: 1.25;
					text-decoration: underline;
					text-transform: capitalize;

					// Medium devices (tablets, 768px and up)
					@include media-breakpoint-up(md) {
						font-size: 18px;
					}

					&:hover {
						color: rgb(91, 91, 91) !important;
					}
				}
			}

			.close {
				position: absolute;
				top: 1%;
				right: -2%;
				margin: 0;
				color: #fff;
				font-size: 5vw;
				font-weight: lighter;
				opacity: 1;
				text-shadow: none;

				// Large devices (desktops, 992px and up)
				@include media-breakpoint-up(lg) {
					font-size: 30px;
				}
			}
		}

		.scrolling-box {
			flex: 1 1 auto;
			-webkit-overflow-scrolling: touch;
			overflow-y: auto;
		}

		.modal-body {
			padding: 0;
			color: #4c4d48;
		}

		.modal-footer {
			flex-direction: column;
			flex-shrink: 0;
			padding: 0 25px;

			> * {
				margin: 0;

				@include fluid-type-default(14px, 20px);

				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
		}
	}

	.scrolling {
		.modal-body {
			padding: 0 20px;
		}

		.modal-content {
			padding: 0 25px 30px 10px;
			border: none;
			background-color: #4c4c4c;

			.scrolling-box {
				max-height: 80vh;
				-webkit-overflow-scrolling: touch;
				overflow-y: scroll;

				&::-webkit-scrollbar {
					width: 1em;
				}

				&::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
				}

				&::-webkit-scrollbar-thumb {
					background-color: $dnaGreenPri;
					outline: 1px solid slategrey;
				}
			}
		}
	}

	.modal-component {
		display: flex;
	}

	.modal-details .modal-content {
		background-color: white;
	}

	&.modal-details {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		width: auto;
		max-width: 668px;
		margin: 30px auto;

		.modal-content {
			height: calc(100vh - 60px);
		}
	}
}

.before-button-content > div {
	display: flex;
	align-items: center;
}

// Large devices (desktops, 992px and up)

.react-toggle-track {
	background-color: #b6b6b4;
}

.react-toggle-thumb {
	border: none;
	box-shadow: 0 6px 7px -2px rgba(182, 182, 180, .45);
}

@include media-breakpoint-down(sm) {
	.modal.fade .modal-dialog,
	.modal.fade .modal-content {
		width: 100%;
		margin: 0 auto;
	}
}
