.mobile-right {
	align-self: flex-start;
	margin: 10px 0 0;

	a {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 24px;
		}

		span {
			display: none;
		}
	}
}

// for tablet and up
@media screen and (min-width: 768px) {
	.mobile-right {
		margin: 5px 0 0;

		a {
			text-decoration: none;

			span {
				display: inline-block;
				color: $black;
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.mobile-right {
		display: none;
	}
}
