.search-tab.business-search-tab {
	// styles here

	// for table and up
	@include media-breakpoint-up(md) {
		.search-group {
			[class*='input-wrapper'] {
				&:first-child {
					flex: 0 0 264px;
				}

				&:nth-child(2) {
					flex: 1;

					.form-control {
						border-radius: 0;
					}
				}
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.search-group {
			[class*='input-wrapper'] {
				&:first-child {
					flex: 0 0 353px;
				}
			}
		}
	}
}
