.incentive {
	p.text {
		margin: 0 0 5px;

		span {
			@include fluid-type-default(14px, 16px);
		}

		&.xs.normal.text-none {
			@include fluid-type-default(14px, 16px);
		}
	}
}

@media screen and (min-width: 768px) {
	.incentive {
		margin-top: 1em;
	}

	// history page
	.recommendations.transactions {
		.incentive {
			margin-top: 0px;
		}
	}
}
