.simple-map-marker {
	position: relative;
	display: flex;
	align-items: flex-end;

	.marker {
		z-index: 1;
		width: 21px;
		height: 30px;
		flex-shrink: 0;
		cursor: pointer;
		transform: scale(1, 1);
		transition: all .15s ease;

		&.daryl {
			width: unset;
			height: 40px;
			border-radius: 50%;
		}
	}

	.restaurant-name {
		position: absolute;
		left: 19px;
		color: rgb(238, 93, 56);
		font-size: 12px;
		font-weight: bold;
		white-space: nowrap;
	}

	.map-card {
		position: absolute;
		z-index: 3;
		left: 10px;
		height: 0px;
		justify-self: center;
		transition: none;
		visibility: hidden;
	}

	&.active {
		.marker {
			transform: scale(1.4, 1.4);
			transition: all .25s ease;
		}

		.restaurant-name {
			left: 23px;
		}
	}

	&.card-active {
		align-items: flex-start;

		.map-card {
			height: unset;
			visibility: visible;
		}

		.restaurant-name {
			visibility: hidden;
		}
	}

	.daryl.marker + .map-card + .restaurant-name {
		left: 40px;
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.map-card {
			position: inherit;
			margin-left: -2px;
			transform: none;
		}
	}
}
