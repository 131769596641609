$image_root_dir: '#{$cdn-basePath}/graphics';

/* Key = image className, Value = image file name */
$images: (
	Di_LoginPic: 'SignIn.jpg',
	Di_HomePic: 'home.jpg',
	Di_RegisterPic: 'registration-hero.jpg',
	Di_BrokenPic: 'broken-plate.jpg',
	Di_HelpBanner: 'HelpBanner.jpg'
);

// Loop over each image className/src key value pair
@each $imageClass, $imageSrc in $images {
	// create a class selector for the current image className
	.#{$imageClass} {
		// apply the current image src as the background image
		background-image: url('#{$image_root_dir}/#{$imageSrc}?w=768');
	}

	/*
    Example output:
    .Di_LoginPic {
      background-image: url("https://dinova-images-dev.imgix.net/graphics/SignIn.jpg?w=768");
    }
  */
}

// Repeat above loop for medium screens
@media screen and (min-width: 768px) {
	@each $imageClass, $imageSrc in $images {
		.#{$imageClass} {
			background-image: url('#{$image_root_dir}/#{$imageSrc}?w=1024');
		}
	}
}

// Repeat for large screen
@media screen and (min-width: 1024px) {
	@each $imageClass, $imageSrc in $images {
		.#{$imageClass} {
			background-image: url('#{$image_root_dir}/#{$imageSrc}?w=1400');
		}
	}
}
