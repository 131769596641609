.Di_MainPopupNav {
	position: absolute;
	z-index: 2000;
	top: 15px;
	left: 10px;
	display: none;

	// Every Level of nav-list
	.nav-list {
		margin: 0;
		background-color: $white;
		list-style-type: none;
	}

	// Fist level nav-list
	> .nav-list {
		-webkit-box-shadow: 5px 5px 8px 0 $black-opacity-3;
		box-shadow: 5px 5px 8px 0 $black-opacity-3;

		> .nav-item {
			padding: 12px 22px;
		}

		> .nav-item:not(:last-child) {
			border-bottom: solid 1px $popup-nav-border-grey;
		}
	}

	// When menu is animating / active / showing
	&.fadeInDown,
	&.fadeOutUp,
	&.did-fade-in {
		display: block;
	}

	.close-icon {
		width: 24px;
		margin-bottom: 10px;
		background-color: $white;
		cursor: pointer;
		object-fit: contain;
	}
}

@media screen and (min-width: 768px) {
	.Di_MainPopupNav {
		top: 15px;
		left: 20px;

		.close-icon {
			width: 30px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.Di_MainPopupNav {
		display: none !important;
	}
}
