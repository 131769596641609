.outer {
	display: flex;
	overflow: auto;
	width: 380px;
	max-width: 100%;
	height: 250px;
	align-items: center;
	justify-content: center;
	padding: 40px;
	margin: 0 auto;
}

@media (min-width: 768px) {
	.outer {
		height: 380px;
	}
}

svg {
	height: 100%;
}

.points-ring {
	circle {
		fill: transparent;
		stroke: $dinova-green;
		stroke-width: 4px;
		transform-origin: 50% 50%;
	}
}

.partial {
	stroke-dasharray: 340%;
	stroke-dashoffset: 120;
	-ms-transform: rotate(-61deg);
	transform: rotate(-61deg);
}

text {
	overflow: hidden;
	width: 90%;
	font-size: 1rem;
	text-overflow: ellipsis;
	white-space: pre-wrap;
}

tspan {
	fill: $black;
	font-size: 1rem;
}

.font-large {
	font-size: 3.6rem;
}

.font-xsmall {
	font-size: .7rem;
}

.point {
	fill: $dinova-green;
}
