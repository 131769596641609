.collapse-with-close {
	position: relative;
	overflow: hidden;
	// margin-top: -15px;
	margin-bottom: 10px;
	transition: height .3s ease;

	.inner-wrapper {
		margin-top: 15px;

		&:before {
			position: absolute;
			top: 0;
			right: 9px;
			width: 0;
			height: 0;
			border-color: transparent transparent $pale-dinova-green; // $pale-dinova-green;
			border-style: solid;
			border-width: 0 15px 15px;
			content: '';
		}

		.close-btn {
			position: absolute;
			top: 22px;
			right: 16px;
			width: 15px;
			height: 15px;
			cursor: pointer;
		}

		.content {
			.text {
				@include fluid-type-default(14px, 16px);

				width: 100%;
				padding: 20px;
				margin-bottom: 0;
				background-color: $pale-dinova-green;
			}
		}
	}

	&.open {
		.inner-wrapper {
			.content {
				background-color: $pale-dinova-green;

				&:before {
					display: block;
				}
			}
		}
	}

	&.closed {
		height: 0px !important;
	}
}
