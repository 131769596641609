.home-page {
	.content-inner {
		padding: 0;

		.search-container {
			background-color: black;
			background-position: top center;
			background-repeat: no-repeat;
			background-size: 160% auto;

			.search-inner {
				// max-width: 940px;
				padding: 45px 0 0;
				margin: 0 auto;

				.ad-slogan {
					display: block;
					margin: 20px 0 87px;
					color: #fff;
					font-size: 46px;
					font-weight: 400;
					line-height: 46px;
					text-align: center;

					.part {
						display: block;
						font-weight: 400;
						text-shadow: rgba(0, 0, 0, .5) 0px 0px 20px;
						text-transform: none;
					}

					h1.part {
						@include fluid-type-default(26px, 32px);

						margin-top: 37px;
					}

					h2.part {
						@include fluid-type-default(18px, 24px);

						margin-top: 12px;
						margin-bottom: 94px;
					}
				}
			}
		}
	}

	// for table and up
	@include media-breakpoint-up(md) {
		.content-inner .search-container {
			background-size: unset;

			.search-inner {
				padding: 56px 25px 5px;

				.ad-slogan {
					margin-top: 11px;
					margin-bottom: 68px;
					font-size: 70px;
					line-height: 70px;

					h1.part {
						@include fluid-type-default(42px, 50px);
					}

					h2.part {
						@include fluid-type-default(30px, 38px);

						margin-bottom: 75px;
					}
				}
			}
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		.content-inner {
			padding: 0;

			.search-container .search-inner {
				padding: 131px 0 45px;

				.ad-slogan {
					margin-bottom: 42px;
					font-size: 85px;

					.part {
						display: inline-block;

						&:first-child {
							margin-right: 10px;
						}
					}

					h1.part {
						@include fluid-type-default(70px, 74px);

						margin-top: 0px;
					}

					h2.part {
						@include fluid-type-default(48px, 52px);

						margin-bottom: 0px;
					}
				}
			}
		}
	}
}
