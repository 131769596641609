// Tab panel with Green Tabs - Ex: HelpFAQ Tab Panel

.tab-panel-header.primary {
	margin-right: 30px;
	margin-left: 30px;
	border-color: $medium-grey;
	border-style: solid;
	border-width: 0 0 2px;

	.tabs {
		max-width: 90%;
		margin-right: auto;
		margin-left: auto;
		border-color: $medium-grey;
		border-width: 0;

		.tab {
			height: 40px;
			padding: 0 10px;
			margin: 3px 3px 0px;
			box-shadow: 0 0 10px 0px rgba(0, 0, 0, .1);
			color: $dinova-green;
			line-height: 1.1;

			@include fluid-type-default(12px, 16px);

			&.active {
				border: none;
				background-color: $dinova-green;
				box-shadow: none;
				color: $white;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.tab-panel-header.primary {
		.tabs {
			max-width: 922px;
		}
	}
}
