.jumobotron {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px;

	.banner-img {
		width: 100%;
		height: 184px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	// This div is generated in withImageSizeBounds.  The height, width, and left styles are handled with javascript there.
	.logo-img-wrapper {
		position: absolute;
		bottom: -30px;
		display: flex;
		align-items: flex-end;

		img.logo-img {
			background-color: white;
			box-shadow: none;
			opacity: 0;
		}

		&.loaded {
			img.logo-img {
				box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, .1), -5px 5px 10px 0px rgba(0, 0, 0, .1);
				opacity: 1;
			}
		}
	}

	.logo-img {
		max-width: 100%;
		max-height: 100%;
		margin: 0 auto;
		box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, .1), -5px 5px 10px 0px rgba(0, 0, 0, .1);
	}
}

@media screen and (min-width: 768px) {
	.jumobotron {
		.banner-img {
			height: 225px;
			// max-height: 140px;
		}
	}
}

@media screen and (min-width: 1440px) {
	.jumobotron {
		.banner-img {
			height: 274px;
		}
	}
}
