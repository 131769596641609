.main-header {
	padding-top: 20px;

	.Di_HeaderPadding {
		display: flex;
		height: auto;
		align-items: center;
		padding: 5px 10px 20px;
	}

	// for tablet and up
	@include media-breakpoint-up(md) {
		.Di_HeaderPadding {
			padding: 0px 20px 20px;
		}
	}

	// for desktop and up
	@include media-breakpoint-up(lg) {
		padding-top: 0;

		.Di_HeaderPadding {
			padding: 20px 25px;

			.register-center {
				flex-direction: column;
			}
		}
	}
}
