.option-item {
	position: relative;
	flex: 1 0 100%;
	padding-left: 30px;
	margin-bottom: 8px;
	color: rgb(112, 112, 117);
	font-size: 18px;
	font-weight: 400;
	line-height: 1.8;
	list-style: none;

	&:before {
		position: absolute;
		top: 12px;
		left: 0;
		width: 20px;
		height: 20px;
		background: url(#{$imageBaseUrl}/icons/icon-detail-checkmark.svg) center no-repeat;
		content: '';
		transform: translateY(-50%);
	}

	// for table and up
	@include media-breakpoint-up(md) {
		flex-basis: 50%;
	}
}

.bullet {
	.option-item {
		padding: 3px 0 0 15px;
		margin: 0;
		list-style: none;

		&:before {
			display: inline-block; /* Needed to add space between the bullet and the text */
			width: 1em; /* Also needed for space (tweak if needed) */
			background: none;
			color: black; /* Change the color */
			content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
			font-weight: bold; /* If you want it to be bold */
		}
	}
}
