.textbox-input {
	position: relative;
	margin-bottom: 15px;

	&.asterisk-mark .md-form input + label:after {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		color: $danger-red;
		content: ' * ';
		opacity: 1;
	}

	&.cross-mark .md-form input + label:after {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		color: $black;
		content: ' ✝ ';
		font-size: 12px;
		opacity: 1;
	}

	.example-label {
		margin-left: 10px;
		color: $black-text;
		font: 14px/18px 'Lato', sans-serif;
	}

	.Di_HasFieldError {
		margin-top: 0;
	}

	.md-form {
		margin-bottom: 0;
	}

	.no-suggestions {
		position: absolute;
		bottom: -30px;
		width: 100%;
		padding: .5rem;
		border: solid $dinova-green 3px;
		color: $deepest-grey;
	}

	.Di_HasSideIcon {
		.no-suggestions {
			width: calc(100% - 50px);
		}
	}

	.suggestions {
		position: absolute;
		z-index: 1;
		top: 40px;
		width: calc(100% - 50px);
		max-height: 300px;
		box-sizing: border-box;
		padding-left: 0;
		margin-top: 56;
		background-color: white;
		border-color: $dinova-green;
		border-style: solid;
		border-width: 0 3px 3px;
		list-style: none;
		overflow-y: auto;
		transition: none;
	}

	&.focused,
	.open + {
		.form-control {
			border: 2px solid $dinova-green !important;
		}

		.suggestions {
			border: 3px solid $dinova-green;
			border-top-width: 0;
		}
	}

	.open.focus + .suggestions {
		top: 38px;
	}

	.suggestions li {
		padding: 1.5rem;
		font: 1em 'Lato', sans-serif;
		font-weight: bold;
	}

	.suggestion-active,
	.suggestions li:hover {
		background-color: $dinova-green;
		color: $white;
		cursor: pointer;
		font-weight: 700;
	}

	.Di_HasError {
		.suggestions {
			border-color: $danger-red;
		}
	}
}

@media screen and (min-width: 768px) {
	.textbox-input {
		.suggestions {
			top: 62px;
		}

		.open.focus + .suggestions {
			top: 62px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.textbox-input {
		.suggestions {
			top: 55px;
		}

		.open.focus + .suggestions {
			top: 53px;
		}
	}
}
