.search-tab-content {
	z-index: 1;
	padding: 22px 13px 10px;
	background-color: $white;

	// for desktop and up
	@include media-breakpoint-up(lg) {
		padding: 13px 7px 10px;
	}
}
