// Main Header Wrapper
.Di_HeaderPadding {
	position: relative;
	max-width: 1440px;
	height: 100px;
	padding: 10px 25px;
	margin-right: auto;
	margin-left: auto;
}

// Required Header
.Di_RequiredHeader {
	margin-bottom: .25em;
	float: right;

	@include fluid-type-default(12px, 14px);

	span {
		color: $required-red;
	}
}
