.search-page-view-toggles {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 13px 15px;

	.text {
		margin: 0;
		white-space: nowrap;

		&.bold.sm.text-none {
			@include fluid-type-default(14px, 20px);

			display: flex;
			align-items: center;
			letter-spacing: .06em;
		}

		.arrow.green {
			margin-bottom: 1px;
			margin-left: 4px;
		}
	}

	.title {
		display: flex;
		margin: 0;
		cursor: pointer;

		.text {
			overflow: visible;
			color: $dinova-green;
		}

		.filter-count {
			display: flex;
			width: 17px;
			height: 17px;
			align-items: center;
			justify-content: center;
			margin: -3px 2px 0px 0;
			background-color: $dinova-green;
			border-radius: 100%;
			color: $white;
			font-size: 12px;
		}
	}

	.toggle-list-btn {
		display: flex;
		height: 20px;
		align-items: center;
		color: $dinova-green;
		cursor: pointer;

		img[src*='map'] {
			width: 25px;
			height: 25px;
		}

		img[src*='list'] {
			width: 20px;
			height: 20px;
			margin-top: 1px;
			margin-right: 3px;
		}

		.label {
			display: inline;
			color: $dinova-green;
			// font-weight: 600;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.search-page-view-toggles {
		.toggle-list-btn {
			.text.label.sm.bold.text-none {
				@include fluid-type-default(12px, 16px);
			}
		}
	}
}
